import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi";
import { DangerRight, Success } from "../../Component/api/toastServices";

const initialState = {
    video: [],
    isLoading: false,
    isSkeleton: false,
    total: null,
}

export const allVideo = createAsyncThunk("admin/video/getVideos",async (payload)=>{
  return  apiInstanceFetch.get(`admin/video/getVideos?start=${payload?.start}&limit=${payload?.limit}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=${payload?.type}`)
})
export const addFakeVideo = createAsyncThunk("admin/video/uploadfakevideo",async (payload)=>{
    
    return  apiInstance.post(`admin/video/uploadfakevideo?userId=${payload?.fakeUserId}`,payload?.formData)
  })
export const updateFakeVideo = createAsyncThunk("admin/video/updatefakevideo",async (payload)=>{
    
    return  apiInstance.patch(`admin/video/updatefakevideo?userId=${payload?.fakeUserId}&videoId=${payload?.mongoId}`,payload?.formData)
  })

  export const deleteFakeVideo = createAsyncThunk("admin/video/deleteVideo",async (payload)=>{
    
    return  apiInstance.delete(`admin/video/deleteVideo?videoId=${payload}`)
  })
  

const videoSlice = createSlice({
    name:"videoSlice",
    initialState,
    reducers:{},
    extraReducers: (builder)=>{

        builder.addCase(allVideo.pending,(state,action)=>{
            state.isSkeleton = true;
        })

        builder.addCase(allVideo.fulfilled, (state,action) => {
            state.video = action.payload.videos;
            state.total = action?.payload?.totalVideo
            state.isSkeleton = false;
        })

        builder.addCase(allVideo.rejected,(state,action)=>{
            state.isSkeleton = false;
        })

        builder.addCase(addFakeVideo.pending,(state,action)=>{
            state.isLoading = true;
        })

        builder.addCase(addFakeVideo.fulfilled, (state,action) => {
            
            if (action.payload.status) {
                state.video.unshift(action.payload.video);
            
                Success("Video Add Successfully")
              }
                state.isLoading = false;
        })

        builder.addCase(addFakeVideo.rejected,(state,action)=>{
            state.isLoading = false;
        })
        builder.addCase(updateFakeVideo.pending,(state,action)=>{
            state.isLoading = true;
        })

        builder.addCase(updateFakeVideo.fulfilled, (state,action) => {
            
            
              if (action.payload.status) {
                  
                  const videoIndex = state.video.findIndex((video) => video?._id === action?.payload?.video?._id);
                  if (videoIndex !== -1) {
                    state.video[videoIndex] = { ...state.video[videoIndex], ...action.payload.videos };
                  }
                  Success("Video Update Successfully")
                }
                else{
                  
                  DangerRight(action.payload.message)
                }
                state.isLoading = false;
  
        })

        builder.addCase(updateFakeVideo.rejected,(state,action)=>{
            state.isLoading = false;
        })

        builder.addCase(deleteFakeVideo.pending, (state, action) => {
            state.isLoading = true;
          })
          builder.addCase(deleteFakeVideo.fulfilled, (state, action) => {
            if (action.payload.status) {
              state.video = state.video.filter((video) => video._id !== action.meta.arg);
              Success("Video Delete Successfully")
            }
            state.isLoading = false;

          })
          builder.addCase(deleteFakeVideo.rejected, (state, action) => {
            state.isLoading = false;
          })
    }

})
export default videoSlice.reducer;