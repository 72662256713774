import React, { useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function MultiButton(props) {
  const { multiButtonSelect, setMultiButtonSelect, label } = props;

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null && newAlignment !== undefined) {
      setMultiButtonSelect(newAlignment);
      if(newAlignment === "Fake User" || newAlignment === "User" || newAlignment === "Profile" || newAlignment === "Avatar"){

      }
    }
  };


  useEffect(() => {
    console.log("multiButtonSelect",multiButtonSelect);
  }, [multiButtonSelect])
  
  return (
    <div className="multiButton">
      {label?.map((item, index) => {
        return (
          <>
            <ToggleButtonGroup
              value={multiButtonSelect}
              exclusive={true}
              onChange={handleAlignment}
              aria-label="text alignment"
            >
              <ToggleButton key={index} value={item} aria-label={item} >
                <span className="text-capitalize">{item}</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        );
      })}
    </div>
  );
}
