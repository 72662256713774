/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi";
import jwt_decode from "jwt-decode";
import { SetDevKey, setToken } from "../../Component/utils/setAuth";
import { key } from "../../Component/utils/config";
import { DangerRight, Success } from "../../Component/api/toastServices";
import axios from "axios";

const initialState = {
  admin: {},
  isAuth: false,
  isLoading: false,
};
export const login = createAsyncThunk("admin/admin/login", async (payload) => {
  return apiInstance.post("admin/admin/login", payload);
});

export const getAdmin = createAsyncThunk("admin/admin/profile", async () => {
  
  return apiInstance.get("admin/admin/profile");
});

export const updateAdmin = createAsyncThunk(
  "admin/admin/updateProfile",
  async (payload) => {
    return apiInstance.patch(
      `admin/admin/updateProfile?adminId=${payload?.adminId}`,
      payload?.formData
    );
  }
);

export const updateAdminPassword = createAsyncThunk(
  "admin/admin/forgotPassword",
  async (payload) => {
    return apiInstanceFetch.post(
      `admin/admin/forgotPassword?adminId=${payload?.adminId}`,
      payload?.data
    );
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    setOldAdmin(state, action) {
      let token = JSON.parse(action.payload);
      state.admin = token;
      state.isAuth = true;
      SetDevKey(key);
    },

    logout(state, action) {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("admin_");
      sessionStorage.removeItem("key");
      sessionStorage.removeItem("isAuth");
      state.admin = {};
      state.isAuth = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload && action.payload.status !== false) {
        
        let token_ = action.payload.token && jwt_decode(action.payload.token);
        state.flax = action.payload.flag;
        state.admin = token_;
        state.isAuth = true;
        state.isLoading = false;
        SetDevKey(key);
        sessionStorage.setItem("token", action.payload.token);
        sessionStorage.setItem("admin_", token_);
        setToken(action.payload.token);
        sessionStorage.setItem("key", key ? key : undefined);
        sessionStorage.setItem("isAuth", true);
        Success("Login successfully");
        state.isLoading = false;
      } else if (action.payload.status === false) {
        DangerRight(action.payload.message);
        state.isLoading = false;
      }
      state.isLoading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
    });

   

    builder.addCase(getAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admin = {
        ...state.admin,
        _id: action.payload?.data?._id,
        flag: action.payload?.data?.flag,
        name: action.payload?.data?.name,
        email: action.payload?.data?.email,
        image: action.payload?.data?.image,
      };
    });

    builder.addCase(getAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAdmin.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.admin = {
        ...state.admin,
        name: action.payload?.admin?.name,
        email: action.payload?.admin?.email,
        image: action.payload?.admin?.image,
      };
      state.isLoading = false;
      Success("Admin Updated Successfully");
    });

    builder.addCase(updateAdmin.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateAdminPassword.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(updateAdminPassword.fulfilled, (state, action) => {
      
      state.isLoading = false;
      state.admin = action?.payload?.admin;
      Success("Admin Updated Successfully");
      // sessionStorage.clear();

      window.location.href = "/login";
    });

    builder.addCase(updateAdminPassword.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default authSlice.reducer;
export const { setOldAdmin, logout } = authSlice.actions;
