import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi";
import { DangerRight, Success } from "../../Component/api/toastServices";
import axios from "axios";

const initialState = {
  user: [],
  isLoading: false,
  isSkeleton: false,
  total: null,
  historyData: [],
  country: [],
  userProfile: {},
};

export const allUsers = createAsyncThunk(
  "admin/user/getUsers",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/user/getUsers?start=${payload?.start}&limit=${payload?.limit}&type=${payload?.type}&startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
  }
);

export const getCountry = createAsyncThunk(
  "https://restcountries.com/v3.1/all",
  async () => {
    const response = await fetch("https://restcountries.com/v3.1/all");
    const data = await response.json();

    return data;
  }
);

export const deleteUser = createAsyncThunk(
  "admin/user/deleteUsers",
  async (id) => {
    return apiInstanceFetch.delete(`admin/user/deleteUsers?userId=${id}`);
  }
);

export const blockUser = createAsyncThunk("admin/user/isBlock", async (id) => {
  
  return apiInstance.patch(`admin/user/isBlock?userId=${id?.id ? id?.id : id}`);
});

export const addFakeUser = createAsyncThunk(
  "admin/user/fakeUser",
  async (data) => {
    return apiInstance.post("admin/user/fakeUser", data);
  }
);

export const updateFakeUser = createAsyncThunk(
  "admin/user/updateUser",
  async (payload) => {
    return apiInstance.patch(
      `admin/user/updateUser?userId=${payload?.id}`,
      payload?.data
    );
  }
);

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(allUsers.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(allUsers.fulfilled, (state, action) => {
      
      state.user = action.payload.data;

      state.isSkeleton = false;
    });

    builder.addCase(allUsers.rejected, (state, action) => {
      state.isSkeleton = false;
    });
    builder.addCase(blockUser.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(blockUser.fulfilled, (state, action) => {
      
      state.isLoading = false;

      if (action.payload.status) {
    
        const responseData = action?.payload?.data;

        // Check if responseData is defined before accessing its properties
        if (responseData) {
          const data = responseData.map((data) => data);
          const userIds = action.payload.data.map((id) => id?._id);
      

          state.user = state?.user?.map((userData) => {
          
            if (userIds?.includes(userData?._id)) {
          

              const matchingUserData = data?.find(
                (user) => user?._id === userData?._id
              );
              
          

              return {
                ...userData,
                isBlock: matchingUserData?.isBlock,
              };
            }

            return userData;
          });
        }
        action?.meta?.arg?.data === true ? Success("User Block successfully") : Success("User UnBlock successfully")
        
      }
    });

    builder.addCase(blockUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteUser.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      if (action.payload.status) {
        const deletedUserIds = action.meta.arg;

        state.isLoading = false;
        state.user = state.user.filter(
          (user) => !deletedUserIds.includes(user._id)
        );

        Success("User deleted successfully");
      } else {
        DangerRight(action.payload.message);
      }
    });

    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getCountry.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCountry.fulfilled, (state, action) => {
      state.historyData = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getCountry.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(addFakeUser.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addFakeUser.fulfilled, (state, action) => {
      if (action.payload.status) {
        
        state.isLoading = false;
        state.user.unshift(action.payload.data);
        Success("Fake User Create Successfully");
      } else {
        DangerRight(action.payload.message);
      }
    });

    builder.addCase(addFakeUser.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateFakeUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateFakeUser.fulfilled, (state, action) => {
      if (action.payload.status) {
        
        const userIdx = state?.user?.findIndex(
          (user) => user?._id === action?.payload?.data?._id
        );
        
        if (userIdx !== -1) {
          
          state.user[userIdx] = {
            ...state.user[userIdx],
            ...action.payload.data,
          };
        }
        Success("User update successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(updateFakeUser.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
export default userSlice.reducer;
