import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditBtn.svg";
import Pagination from "../../extra/Pagination";
import Button from "../../extra/Button";
import Table from "../../extra/Table";
import dayjs from "dayjs";
import NewTitle from "../../extra/Title";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import { permissionError, warning } from "../../utils/Alert";
import { allVideo, deleteFakeVideo } from "../../../redux/slice/videoSlice";

function Video(props) {
  const { video, total } = useSelector((state) => state.video);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const hasPermission = useSelector((state) => state.auth.admin.flag);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectCheckData, setSelectCheckData] = useState([]);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const { startDate, endDate, multiButtonSelectData } = props;

  useEffect(() => {
    const payload = {
      type: "realVideo",
      start: page,
      limit: size,
      startDate,
      endDate,
    };
    dispatch(allVideo(payload));
  }, [dispatch, page, size, startDate, endDate]);

  useEffect(() => {
    setData(video);
  }, [video]);

  const handleSelectCheckData = (e, row) => {
    if (!hasPermission) return permissionError();
    const checked = e.target.checked;
    if (checked) {
      setSelectCheckData((prevSelectedRows) => [...prevSelectedRows, row]);
    } else {
      setSelectCheckData((prevSelectedRows) =>
        prevSelectedRows.filter((selectedRow) => selectedRow._id !== row._id)
      );
    }
  };
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    if (!hasPermission) return permissionError();
    setSelectAllChecked(checked);
    if (checked) {
      setSelectCheckData([...data]);
    } else {
      setSelectCheckData([]);
    }
  };

  const videoTable = [
    {
      Header: "NO",
      body: "name",
      Cell: ({ index }) => <span>{(page - 1) * size + index + 1}</span>,
    },

    {
      Header: "Video",
      body: "video",
      Cell: ({ row }) => (
        <video
          controls
          width="150px"
          height="100px"
          style={{ borderRadius: "10px" }}
          src={row?.videoUrl}
          muted
        />
      ),
    },
    {
      Header: "Image",
      body: "videoImage",
      Cell: ({ row }) => (
        <img src={row?.videoImage} width="60px" height="60px" />
      ),
    },
    {
      Header: "User",
      body: "name",
      Cell: ({ row }) => (
        <span className="text-capitalize fw-bold">{row?.name}</span>
      ),
    },
    {
      Header: "Location",
      body: "location",
      Cell: ({ row }) => (
        <span className="text-capitalize fw-bold">{row?.location}</span>
      ),
    },
    {
      Header: "Like Count",
      body: "totalLikes",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.totalLikes}</span>
      ),
    },
    {
      Header: "Video Time",
      body: "videoTime",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.videoTime}</span>
      ),
    },
    {
      Header: "CREATE DATE",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMMM YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "ACTION",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          {/* <Button
            btnIcon={<EditIcon />}
            onClick={() => {
              dispatch(openDialog({ type: "fakeVideo", data: row }));
            }}
          /> */}
          <Button
            btnIcon={<TrashIcon />}
            onClick={() => handleDeleteVideo(row)}
          />
        </div>
      ),
    },
  ];

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };

  const handleDeleteVideo = (row) => {
   if (!hasPermission) return permissionError();
    
    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteFakeVideo(row?._id));
        }
      })
      .catch((err) => console.log(err));
  };



  return (
    <div>
      <div className="user-table mb-3">
        {/* {dialogueType == "premiumPlanAdd" && <CreatePremiumPlan />} */}

        <div className="user-table-top">
          <div className="row align-items-start">
            <div className="col-6">
              <h5
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginBottom: "4px",
                }}
              >
                Video
              </h5>
            </div>
          </div>
        </div>

        <Table
          data={data}
          mapData={videoTable}
          serverPerPage={size}
          serverPage={page}
          handleSelectAll={handleSelectAll}
          selectAllChecked={selectAllChecked}
          type={"server"}
        />
        <div className="mt-3">
          <Pagination
            type={"server"}
            activePage={page}
            rowsPerPage={size}
            userTotal={total}
            setPage={setPage}
            handleRowsPerPage={handleRowsPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Video;
