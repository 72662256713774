import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditBtn.svg";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { deleteFrame, getFrame } from "../../../redux/slice/avtarFrameSlice";
import CreateFrame from "./CreateFrame";
import { permissionError, warning } from "../../utils/Alert";
import { isLoading } from "../../utils/allSelector";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Frame = () => {
  const { frame, total } = useSelector((state) => state.frame);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);
  const hasPermission = useSelector((state) => state.auth.admin.flag)
  const loader = useSelector(isLoading);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);

 

  useEffect(() => {
    let payload = {
      startDate: "All",
      endDate: "All",
    };
    dispatch(getFrame(payload));
  }, [dispatch]);

  useEffect(() => {
    setData(frame);
  }, [frame]);

  const handleDeleteFrame = (row) => {
   if (!hasPermission) return permissionError();

    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        if (yes) {
          dispatch(deleteFrame(row?._id));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="mb-3">
        {dialogueType == "avtarFrame" && <CreateFrame />}
        <div className="user-table-top">
          <div className="row align-items-start">
            <div className="col-6">
              <h5
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginBottom: "4px",
                }}
              >
                Avtar
              </h5>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <div className="ms-auto mt-2">
                <div className="new-fake-btn d-flex ">
                  <Button
                    btnIcon={<AddIcon />}
                    btnName={"New"}
                    newClass={"rounded"}
                    onClick={() => {
                      dispatch(openDialog({ type: "avtarFrame" }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          <div className="row">
            {data.map((item) => {
              return (
                <div className="col-12 col-sm-6 col-md-4 col-xl-2 mt-4">
                  <div className="mainFrame">
                    <div className="d-flex justify-content-between align-items-center">
                      {loader === true ? (
                        <>
                          <SkeletonTheme
                            baseColor="#e2e5e7"
                            highlightColor="#fff"
                          >
                            <p className="d-flex justify-content-center ">
                              <Skeleton
                                height={100}
                                width={100}
                                style={{borderRadius : "50%"}}
                              />
                            </p>
                          </SkeletonTheme>
                        </>
                      ) : (
                        <>
                          <img src={item?.avtarFrame} alt="" />
                        </>
                      )}
                      <div className="action-button">
                      
                        <Button
                          btnIcon={<TrashIcon />}
                          onClick={() => handleDeleteFrame(item)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      </div>
    </div>
  );
};

export default Frame;
