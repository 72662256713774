import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Input from "../../extra/Input";
import { useDispatch, useSelector } from "react-redux";
import Selector from "../../extra/Selector";
import Button from "../../extra/Button";
import ReactAudioPlayer from "react-audio-player";
import {
  addSong,
  getSongCategory,
  updateSong,
} from "../../../redux/slice/songSlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};

const CreateSong = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const { songCategory } = useSelector((state) => state.song);
  const [addSongOpen, setAddSongOpen] = useState(false);
  const [mongoId, setMongoId] = useState();
  const [singerName, setSingerName] = useState();
  const [soundTitle, setSoundTitle] = useState();
  const [soundLink, setSoundLink] = useState();
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");
  const [soundCategoryId, setSoundCategoryId] = useState();
  const [soundCategoryDataGet, setSoundCategoryDataGet] = useState();
  const [soundTime, setSoundTime] = useState();
  const [showSound, setShowSound] = useState();

  const [error, setError] = useState({
    singerName: "",
    soundTitle: "",
    soundLink: "",
    image: "",
    soundLink: "",
    soundTime: "",
  });

  useEffect(() => {
    dispatch(getSongCategory());
  }, [dispatch]);

  useEffect(() => {
    setAddSongOpen(dialogue);
    if (dialogueData) {
      setMongoId(dialogueData?._id);
      setSingerName(dialogueData?.singerName);
      setSoundCategoryId(dialogueData?.songCategoryId?._id);
      setSoundTime(dialogueData?.songTime);
      setSoundTitle(dialogueData?.songTitle);
      setShowSound(dialogueData?.songLink);
      setImagePath(dialogueData?.songImage);
      setSoundLink(dialogueData?.songLink);
    }
  }, [dialogue, dialogueData]);

  useEffect(() => {
    setSoundCategoryDataGet(songCategory);
  }, [songCategory]);

  const handleCloseAddCategory = () => {
    setAddSongOpen(false);
    dispatch(closeDialog());
  };
  const handleSoundUpload = async (event) => {
    const file = event.target.files[0];
    setSoundLink(event.target.files[0]);
    setShowSound(URL.createObjectURL(event.target.files[0]));

    const selectedFile = event.target.files[0];
    const videoElement = document.createElement("video");
    videoElement.src = URL.createObjectURL(selectedFile);
    videoElement.addEventListener("loadedmetadata", () => {
      const durationInSeconds = videoElement.duration;
      const durationInMilliseconds = durationInSeconds;
      setSoundTime(parseInt(durationInMilliseconds));
    });

    if (!event.target.files[0]) {
      return setError({
        ...error,
        soundLink: `Sound Is Required`,
      });
    } else {
      return setError({
        ...error,
        soundLink: "",
      });
    }
  };
  const handleFileUpload = async (event) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }

    if (!event.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  const handleSubmit = () => {
    if (
      !singerName ||
      !soundTitle ||
      !soundCategoryId ||
      !soundTime ||
      !soundLink
    ) {
      let error = {};
      if (!singerName) error.singerName = "Name is required";
      if (!soundTitle) error.soundTitle = "Sound Title is required";
      if (!soundCategoryId)
        error.soundCategoryId = "Sound CategoryId is required";
      if (!soundTime) error.soundTime = "Sound Time is required";
      if (!soundLink) error.soundLink = "Sound Link is required";

      return setError({ ...error });
    } else {
      const formData = new FormData();

      formData.append("singerName", singerName);
      formData.append("songTitle", soundTitle);
      formData.append("songCategoryId", soundCategoryId);
      formData.append("songTime", soundTime);
      formData.append("songLink", soundLink);
      if (image) {
        formData.append("songImage", image);
      }

      if (mongoId) {
        let payload = { mongoId, formData };
        dispatch(updateSong(payload));
      } else {
        dispatch(addSong(formData));
      }

      dispatch(closeDialog());
    }
  };

  return (
    <div>
      <Modal
        open={addSongOpen}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {dialogueData ? "Edit Sound" : "Add Sound"}
          </Typography>
          <form>
            <div className="row sound-add-box">
              <div className="col-lg-6 col-sm-12">
                <Input
                  label={"Singer Name"}
                  name={"name"}
                  placeholder={"Enter Details..."}
                  value={singerName}
                  errorMessage={error.singerName && error.singerName}
                  onChange={(e) => {
                    setSingerName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        singerName: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        singerName: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Input
                  label={"Sound Title"}
                  name={"soundTitle"}
                  placeholder={"Enter Details..."}
                  value={soundTitle}
                  errorMessage={error.soundTitle && error.soundTitle}
                  onChange={(e) => {
                    setSoundTitle(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        soundTitle: `Sound Title Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        soundTitle: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-12 mt-2">
                <Selector
                  label={"Sound Category"}
                  selectValue={soundCategoryId}
                  placeholder={"Select Category"}
                  selectData={soundCategoryDataGet}
                  selectId={true}
                  errorMessage={error.soundCategoryId && error.soundCategoryId}
                  onChange={(e) => {
                    setSoundCategoryId(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        soundCategoryId: `Sound Category Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        soundCategoryId: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-lg-6 col-sm-12 mt-2">
                <Input
                  label={"Sound Time (seconds)"}
                  name={"soundTime"}
                  disabled={true}
                  placeholder={"Sound Upload"}
                  value={soundTime}
                />
              </div>
              <div className="col-lg-6 col-sm-12 mt-2">
                <Input
                  type={"file"}
                  label={"Sound Upload"}
                  accept={".mp3,audio/*"}
                  errorMessage={error.soundLink && error.soundLink}
                  onChange={handleSoundUpload}
                />
                {showSound && (
                  <div className="mt-3">
                    <ReactAudioPlayer
                      src={showSound}
                      controls
                      autoPlay
                      style={{ width: "250px" }}
                      onPlay={() => console.log("Audio is playing")}
                      onError={(error) => console.error("Audio error:", error)}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-6 col-sm-12 mt-2">
                <Input
                  type={"file"}
                  label={"Sound Image"}
                  accept={"image/png, image/jpeg"}
                  errorMessage={error.image && error.image}
                  onChange={handleFileUpload}
                />
                {imagePath && (
                  <div>
                    <img
                      height="100px"
                      width="100px"
                      alt="app"
                      src={imagePath}
                      style={{
                        boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                        // border: "2px solid #fff",
                        borderRadius: 10,
                        marginTop: 10,
                        float: "left",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-12 col-sm-12 mt-3 audio-upload">
                <div
                  className="video-upload-loader"
                  style={{ width: "100%", height: "unset" }}
                ></div>
              </div>
              <div className="col-lg-12 col-sm-12 mt-3 fake-create-img">
                <div
                  className="video-upload-loader"
                  style={{ width: "100%", height: "unset" }}
                ></div>
              </div>
            </div>
            <div className="mt-3 pt-3 d-flex justify-content-end">
              <Button
                onClick={handleCloseAddCategory}
                btnName={"Close"}
                newClass={"close-model-btn"}
              />
              <Button
                onClick={handleSubmit}
                btnName={"Submit"}
                type={"button"}
                newClass={"submit-btn-dialogue"}
                style={{
                  borderRadius: "0.5rem",
                  width: "88px",
                  marginLeft: "10px",
                }}
              />
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateSong;
