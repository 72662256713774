import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi";
import axios from "axios";
import { actions } from "react-table";
import { DangerRight, Success } from "../../Component/api/toastServices";

const initialState = {
  report: [],
  isLoading: false,
  isSkeleton: false,
  total: null,
};

export const getVideoReport = createAsyncThunk(
  "admin/report/videoReports",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/report/videoReports?startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=${payload?.type}`
    );
  }
);
export const getPostReport = createAsyncThunk(
  "admin/report/postReports",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/report/postReports?startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=${payload?.type}`
    );
  }
);

export const solvedReport = createAsyncThunk(
  "admin/report/solveReport",
  async (payload) => {
    
    return apiInstance.put(`admin/report/solveReport?reportId=${payload}`);
  }
);
export const deleteReport = createAsyncThunk(
  "admin/report/deleteReport",
  async (payload) => {
    return apiInstance.delete(`admin/report/deleteReport?reportId=${payload}`);
  }
);

const reportSlice = createSlice({
  name: "reportSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVideoReport.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getVideoReport.fulfilled, (state, action) => {
      state.report = action.payload.report;
      state.total = action?.payload?.totalCount;
      state.isSkeleton = false;
    });

    builder.addCase(getVideoReport.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(solvedReport.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(solvedReport.fulfilled, (state, action) => {
      
      if (action.payload.status) {
        
        const reportIndex = state.report.findIndex(
          (report) => report?._id === action?.payload?.report?._id
        );
        if (reportIndex !== -1) {
          state.report[reportIndex] = {
            ...state.report[reportIndex],
            ...action.payload.report,
          };
        }
        Success("Report solved successfully");
      } else {
        
        DangerRight(action.payload.message);
      }
      state.isLoading = false;
    });

    builder.addCase(solvedReport.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteReport.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deleteReport.fulfilled, (state, action) => {
      if (action.payload.status) {
        state.report = state.report.filter((report) => report._id !== action.meta.arg);
        Success("Report Delete Successfully")
      }
      state.isLoading = false;

    })
    builder.addCase(deleteReport.rejected, (state, action) => {
      state.isLoading = false;
    })

    builder.addCase(getPostReport.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getPostReport.fulfilled, (state, action) => {
      state.report = action.payload.report;
      state.total = action?.payload?.totalCount;
      state.isSkeleton = false;
    });

    builder.addCase(getPostReport.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});

export default reportSlice.reducer;
