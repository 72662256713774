import React, { useState } from "react";
import NewTitle from "../../extra/Title";
import Video from "./Video";
import FakeVideo from "./FakeVideo";
import CreateFakeVideo from "./CreateFakeVideo";
import { useSelector } from "react-redux";

export default function ManageVideo() {

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [dayAnalytics, setDayAnalytics] = useState("today");
  const [multiButtonSelect, setMultiButtonSelect] = useState("Real Video");
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");
  

  return (
    <div className="userPage channelPage">
      { dialogueType == "fakeVideo" && <CreateFakeVideo />}
      <div>
        <div className="dashboardHeader primeHeader mb-3 p-0">
          <NewTitle
            dayAnalyticsShow={true}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            name={`Video`}
            titleShow={true}
            multiButtonSelect={multiButtonSelect}
            setMultiButtonSelect={setMultiButtonSelect}
            labelData={["Real Video", "Fake Video"]}
          />
        </div>
      </div>
      {multiButtonSelect == "Real Video" && (
        <Video startDate={startDate} endDate={endDate} />
      )}
      {multiButtonSelect == "Fake Video" && (
        <FakeVideo startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
}
