import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi"
import { Success } from "../../Component/api/toastServices"

const initialState ={
    settingData : [],
    isLoading : false
}



export const getSetting = createAsyncThunk("admin/setting/getSetting", async (payload) => {
    return apiInstanceFetch.get("admin/setting/getSetting")
})

export const updateSetting = createAsyncThunk("admin/setting/updateSetting", async (payload) => {
    
    return apiInstance.patch(`admin/setting/updateSetting?settingId=${payload?.id}`,payload?.data)
})

export const maintenanceMode = createAsyncThunk("admin/setting/appActive", async (id) => {
    
    return apiInstance.put(`admin/setting/appActive?id=${id}`)
})


export const handleSetting = createAsyncThunk("admin/setting/handleSwitch", async (payload) => {
    
    return apiInstance.patch(`admin/setting/handleSwitch?settingId=${payload?.id}&type=${payload?.type}`)
})

export const deleteReview = createAsyncThunk("admin/review/delete", async (id) => {

    return apiInstance.delete(`admin/review/delete/${id}`)
})

const settingSlice = createSlice({
    name: "settingSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(getSetting.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(getSetting.fulfilled, (state, action) => {
            state.isLoading = false;
            state.settingData = action.payload.setting
        })

        builder.addCase(getSetting.rejected, (state, action) => {
            state.isLoading = false;
        })

        builder.addCase(updateSetting.pending, (state) => {
            
            state.isLoading = true;
          });
      
          builder.addCase(updateSetting.fulfilled, (state, action) => {
              if (action.payload.status) {
                  state.settingData = { ...state.settingData, ...action.payload.setting };
                  Success("Setting Updated Successfully");
                }
                state.isLoading = false;
          });
      
          builder.addCase(updateSetting.rejected, (state) => {
            
          });
        
          builder.addCase(maintenanceMode.fulfilled, (state, action) => {
            if (action.payload.status) {
            
                state.settingData = { ...state.settingData, ...action.payload.setting };
                Success("Maintenance Mode Updated Successfully");
              }
              state.isLoading = false;
        });

        builder.addCase(deleteReview.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(deleteReview.fulfilled, (state, action) => {
            if (action.payload.status) {
                state.settingData = state.settingData.filter((setting) => setting._id !== action.meta.arg);
            }
            state.isLoading = false;
        })

        builder.addCase(deleteReview.rejected, (state, action) => {
            state.isLoading = false;
        })
        builder.addCase(handleSetting.pending, (state, action) => {
            state.isLoading = true;
        })

        builder.addCase(handleSetting.fulfilled, (state, action) => {
            state.isLoading = false;

            state.settingData = action.payload.setting;
            Success('Updated Successfully');
        })

        builder.addCase(handleSetting.rejected, (state, action) => {
            state.isLoading = false;
        })


    }
})
export default settingSlice.reducer;