import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

import React, { useEffect } from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Dashboard from "./Dashboard";
import Post from "../Table/Post/Post";
import ManagePost from "../Table/Post/ManagePost";
import ManageUser from "../Table/User/ManageUser";
import ManageVideo from "../Table/Video/ManageVideo";
import Frame from "../Table/AvtarFrame/Frame";
import ManageSong from "../Table/Song/ManageSong";
import Profile from "./Profile";
import Setting from "../Table/Setting/Setting";
import ManageReport from "../Table/Report/ManageReport";

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      location.pathname == "/" ||
      location.pathname == "/admin" ||
      location.pathname == "/admin/" ||
      location.pathname == ""
    ) {
      
      navigate("/admin/adminDashboard");
    }
  }, []);
  var webSize = $(window).width();

  return (
    <div>
      <div className="mainContainer d-flex w-100">
        <div className="containerLeft">
          <Sidebar />
        </div>
        <div className="containerRight w-100 ">
          <Navbar />
          <div className="mainAdmin ml-4 ms-4">
            <div className="mobSidebar-bg  d-none"></div>
            <Routes>
              <Route path="/adminDashboard" element={<Dashboard />}  />
              <Route path="/post" element={<ManagePost />}  />
              <Route path="/video" element={<ManageVideo />}  />
              <Route path="/frame" element={<Frame />}  />
              <Route path="/user" element={<ManageUser />}  />
              <Route path="/song" element={<ManageSong />}  />
              <Route path="/report" element={<ManageReport />}  />
           
              <Route path="/mainSetting" element={<Setting />}  />
              <Route path="/adminProfile" element={<Profile />}  />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
