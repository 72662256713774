import { configureStore } from "@reduxjs/toolkit"
import authSlice from "./slice/authSlice";
import dialogueSlice from "./slice/dialogueSlice";
import userSlice from "./slice/userSlice";
import postSlice from "./slice/postSlice";
import avtarFrameSlice from "./slice/avtarFrameSlice";
import videoSlice from "./slice/videoSlice";
import songSlice from "./slice/songSlice";
import reportSlice from "./slice/reportSlice";
import dashSlice from "./slice/dashSlice";
import settingSlice from "./slice/settingSlice";

const store = configureStore({
    reducer: {
        auth: authSlice,
        dialogue: dialogueSlice,
        user: userSlice,
        post:postSlice,
        frame: avtarFrameSlice,
        video: videoSlice,
        song: songSlice,
        report : reportSlice,
        dashboard : dashSlice,
        setting: settingSlice
    },
    // devTools:false

});

export default store;