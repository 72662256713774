import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Selector from "../../extra/Selector";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import SmallLoader from "../../extra/SmallLoader";
import { allUsers, getCountry } from "../../../redux/slice/userSlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import ReactDropzone from "react-dropzone";
import { addFakeVideo, updateFakeVideo } from "../../../redux/slice/videoSlice";
import { permissionError } from "../../utils/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};

const CreateFakeVideo = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const hasPermission = useSelector((state) => state.auth.admin.flag)
  const { user, historyData } = useSelector((state) => state.user);

  const [mongoId, setMongoId] = useState("");
  const [addVideoOpen, setAddVideoOpen] = useState(false);
  const [caption, setCaption] = useState("");
  const [location, setLocation] = useState("");
  const [countryDataSelect, setCountryDataSelect] = useState();
  const [countryData, setCountryData] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [fakeUserId, setFakeUserId] = useState();
  const [videoTime, setVideoTime] = useState();
  const [fakeUserData, setFakeUserData] = useState([]);
  const [video, setVideo] = useState({
    file: "",
    thumbnailBlob: "",
  });
  const [videoPath, setVideoPath] = useState(null);
  const [thumbnail, setThumbnail] = useState([]);
  const [thumbnailKey, setThumbnailKey] = useState(0);

  const [error, setError] = useState({
    caption: "",
    video: "",
    fakeUserId: "",
    country: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    setAddVideoOpen(dialogue);
    if (dialogueData) {
      
      setMongoId(dialogueData?._id);
      setCaption(dialogueData?.caption);
      setCountryDataSelect(dialogueData?.location);
      setFakeUserId(dialogueData?.userId);
      setVideoPath(dialogueData?.videoUrl);
      setThumbnail(dialogueData?.videoImage)
      setVideoTime(dialogueData?.videoTime);
    }
  }, [dialogue, dialogueData]);
  useEffect(() => {
    const payload = {
      type: "fakeUser",
      start: 1,
      limit: 100,
      startDate: "All",
      endDate: "All",
    };
    dispatch(allUsers(payload));
    dispatch(getCountry());
  }, [dispatch]);

  useEffect(() => {
    setFakeUserData(user);
  }, [user]);

  useEffect(() => {
    const countryName = historyData?.map((item) => item?.name?.common);
    setCountryData(countryName);
    const countryDataSelectData = historyData.filter((item) => {
      const countrySelect = item?.name?.common;
      return countrySelect?.toLowerCase() === countryDataSelect;
    });
    if (countryDataSelectData?.length > 0) {
      const getLatitude = countryDataSelectData?.map((item) => {
        setLatitude(item?.latlng[0]);
        setLongitude(item?.latlng[1]);
      });
    }
    console.log("countryDataSelect", countryDataSelect);
  }, [historyData, countryDataSelect]);

  const handleVideo = async (e) => {
    
    const file = e.target.files[0];
    setVideoPath(URL.createObjectURL(e.target.files[0]));

    if (file) {
      const thumbnailBlob = await generateThumbnailBlob(file);

      if (thumbnailBlob) {
        const videoFileName = file ? file?.name : "video";
        const thumbnailFileName = `${videoFileName.replace(
          /\.[^/.]+$/,
          ""
        )}.jpeg`;

        const thumbnailFile = new File([thumbnailBlob], thumbnailFileName, {
          type: "image/jpeg",
        });
        setThumbnail(thumbnailFile);
        console.log("thumbnailFilethumbnailFilethumbnailFile", thumbnailFile);
        setVideo({
          file: file,
          thumbnailBlob: thumbnailFile,
        });
      }
      setThumbnailKey((prevKey) => prevKey + 1);
    } else {
      setError((prevErrors) => ({
        ...prevErrors,
        video: "Please select a video!",
      }));
    }
    const selectedFile = e.target.files[0];

    const videoElement = document.createElement("video");
    if (selectedFile) {
      
      videoElement.src = URL.createObjectURL(selectedFile);
      videoElement.addEventListener("loadedmetadata", () => {
        const durationInSeconds = videoElement.duration;
        const durationInMilliseconds = durationInSeconds;
        setVideoTime(parseInt(durationInMilliseconds));
      });
    }
  };

  const generateThumbnailBlob = async (file) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = () => {
        video.currentTime = 1; // Set to capture the frame at 1 second
      };

      video.onseeked = async () => {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to blob
        canvas.toBlob((blob) => {
          resolve(blob);
        }, "image/jpeg");
      };

      const objectURL = URL.createObjectURL(file);
      video.src = objectURL;

      return () => {
        URL.revokeObjectURL(objectURL);
      };
    });
  };

  const handleCloseAddCategory = () => {
    setAddVideoOpen(false);
    dispatch(closeDialog());
    // localStorage.setItem("dialogueData", JSON.stringify(dialogueData));
  };

  const handleSubmit = () => {
    if(!hasPermission) return permissionError()
    if (!caption || !fakeUserId || video?.length === 0 || !countryDataSelect) {
      let error = {};
      if (!caption) error.caption = "Caption Is Required !";
      if (!fakeUserId) error.fakeUserId = "User Is Required !";
      if (!countryDataSelect) error.country = "Country is required !";
      if (video?.length === 0) error.video = "Please select video!";

      return setError({ ...error });
    } else {
      
      let formData = new FormData();

      formData.append("caption", caption);
      formData.append("videoUrl", video?.file);
      formData.append("videoImage", thumbnail);
      formData.append("location", countryDataSelect);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);
      formData.append("videoTime", videoTime);

      if (mongoId) {
        
        let payload = { formData, fakeUserId, mongoId };
        dispatch(updateFakeVideo(payload));
      } else {
        
        let payload = { formData, fakeUserId };
        dispatch(addFakeVideo(payload));
      }
      dispatch(closeDialog());
    }
  };

  return (
    <div>
      <Modal
        open={addVideoOpen}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {dialogueData ? "Edit Video" : "Add Video"}
          </Typography>
          <form>
            <div className="row sound-add-box">
              {dialogueData ? (
                ""
              ) : (
                <div className="col-12 col-lg-6 col-sm-6  mt-2 country-dropdown">
                  <Selector
                    label={"Fake User"}
                    selectValue={fakeUserId}
                    placeholder={"Enter Details..."}
                    selectData={fakeUserData}
                    selectId={true}
                    errorMessage={error.fakeUserId && error.fakeUserId}
                    onChange={(e) => {
                      setFakeUserId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          fakeUserId: `Fake User Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          fakeUserId: "",
                        });
                      }
                    }}
                  />
                </div>
              )}
              <div className="col-lg-6 col-sm-12">
                <Input
                  label={"Caption"}
                  name={"caption"}
                  placeholder={"Enter Details..."}
                  value={caption}
                  errorMessage={error.caption && error.caption}
                  onChange={(e) => {
                    setCaption(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        caption: `Caption Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        caption: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2 country-dropdown">
                <Selector
                  label={"Country"}
                  selectValue={countryDataSelect}
                  placeholder={"Enter Details..."}
                  selectData={countryData}
                  errorMessage={error.country && error.country}
                  onChange={(e) => {
                    setCountryDataSelect(e.target.value);
                    if (!e.target.value && !dialogueData) {
                      return setError({
                        ...error,
                        country: `Country Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        country: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2">
                <Input
                  label={"Latitude"}
                  name={"latitude"}
                  disabled={true}
                  value={latitude}
                  placeholder={"Country Select"}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2">
                <Input
                  label={"Longitude"}
                  disabled={true}
                  name={"longitude"}
                  value={longitude}
                  placeholder={"Country Select"}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2">
                <Input
                  label={"Video Time (Seconds)"}
                  name={"videoTime"}
                  accept={"video/*"}
                  placeholder={"Video Time"}
                  value={videoTime}
                  disabled={true}
                />
              </div>
              <div className="col-12 mt-2">
                <Input
                  label={`Video`}
                  id={`video`}
                  type={`file`}
                  accept={`video/*`}
                  errorMessage={error.video && error.video}
                  onChange={handleVideo}
                />
              </div>

              {video.file ? (
                <div className="col-12 d-flex mt-4">
                  <video
                    controls
                    style={{ width: "150px", height: "150px" }}
                    src={video?.file ? URL?.createObjectURL(video?.file) : ""}
                  />
                  <img
                    src={
                      video?.thumbnailBlob
                        ? URL?.createObjectURL(video?.thumbnailBlob)
                        : ""
                    }
                    style={{
                      width: "150px",
                      height: "150px",
                      marginLeft: "20px",
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="col-12 d-flex mt-4">
                    <video
                      controls
                      style={{ width: "200px", height: "200px" }}
                      src={videoPath}
                    />
                    <img
                      src={thumbnail}
                      style={{
                        width: "200px",
                        height: "200px",
                        marginLeft: "20px",
                      }}
                    />
                  </div>
                </>
              )}
              
            </div>
            <div className="mt-3 pt-3 d-flex justify-content-end">
              <Button
                onClick={handleCloseAddCategory}
                btnName={"Close"}
                newClass={"close-model-btn"}
              />
              {dialogueData ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Update"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Submit"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export default CreateFakeVideo;
