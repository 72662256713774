import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getAdmin,
  updateAdmin,
  updateAdminPassword,
} from "../../redux/slice/authSlice";
import Input from "../extra/Input";
import Button from "../extra/Button";
import { permissionError } from "../utils/Alert";

function Profile() {
  const { admin } = useSelector((state) => state.auth);
  const hasPermission = useSelector((state) => state.auth.admin.flag);

  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState();
  const [error, setError] = useState({
    name: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    dispatch(getAdmin());
  }, [dispatch]);

  useEffect(() => {
    setData(admin);
  }, [admin]);

  useEffect(() => {
    setName(admin?.name);
    setEmail(admin?.email);
    setOldPassword(admin?.password);
    setImagePath(admin?.image);
  }, [admin]);

  const handleFileUpload = async (event) => {
    if (!hasPermission) return permissionError();
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }

    const formData = new FormData();

    formData.append("image", event.target.files[0]);

    let payload = {
      adminId: admin?._id,
      formData,
    };

    dispatch(updateAdmin(payload));
  };

  const handleEditProfile = () => {
    if (!hasPermission) return permissionError();
    if (!email || !name) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!name) error.name = "Name Is Required !";
      return setError({ ...error });
    } else {
      const formData = new FormData();

      formData.append("email", email);
      formData.append("name", name);
      formData.append("image", image);

      let payload = {
        adminId: admin?._id,
        formData,
      };

      dispatch(updateAdmin(payload));
    }
  };

  const handlePassword = () => {
    if (!hasPermission) return permissionError();
    if (
      !newPassword ||
      !oldPassword ||
      !confirmPassword ||
      (newPassword !== confirmPassword)
    ) {
      let error = {};
      if (!newPassword) error.newPassword = "New Password Is Required !";
      if (!oldPassword) error.oldPassword = "Old Password Is Required !";
      if (!confirmPassword)
        error.confirmPassword = "Confirm Password Is Required !";
      if (newPassword !== confirmPassword) error.confirmPassword = "Passwords do not match!";

      return setError({ ...error });
    } else {
      let payload = {
        adminId: admin?._id,
        data: {
          oldPass: oldPassword,
          newPass: newPassword,
          confirmPass: confirmPassword,
        },
      };

      dispatch(updateAdminPassword(payload));
    }
  };

  return (
    <div>
      <div className="profile-page payment-setting">
        <div className="dashboardHeader primeHeader mb-3 p-0">
          {/* <NewTitle
            dayAnalyticsShow={false}
            titleShow={true}
            name={`Profile`}
          /> */}
        </div>
        <div className="payment-setting-box p-3">
          <div className="row" style={{ padding: "15px" }}>
            <div className="col-lg-6 col-sm-12 ">
              <div className="mb-4 ">
                <div className="withdrawal-box  profile-img d-flex flex-column align-items-center">
                  <h6 className="text-start">Profile Avatar</h6>
                  <di style={{ paddingTop: "14px" }}>
                    <label for="image" onChange={handleFileUpload}>
                      <div className="avatar-img-icon">
                        <i class="fa-solid fa-camera d-flex justify-content-center  rounded-circle  p-2 cursorPointer"></i>
                      </div>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        style={{ display: "none" }}
                      />
                      {imagePath && (
                        <img
                          src={imagePath}
                          // onError={(e) => {
                          //   e.target.src = UserImage;
                          // }}
                        />
                      )}
                    </label>
                  </di>
                  <h5 className="fw-semibold boxCenter mt-2">{data?.name}</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="mb-4">
                <div className="withdrawal-box payment-box">
                  <h6 className="mb-0">Edit Profile</h6>
                  <div className="row">
                    <form>
                      <div className="row">
                        <div className="col-12 withdrawal-input">
                          <Input
                            label={"Name"}
                            name={"name"}
                            type={"text"}
                            value={name}
                            errorMessage={error.name && error.name}
                            placeholder={"Enter Detail...."}
                            onChange={(e) => {
                              setName(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  name: `Name Is Required`,
                                });
                              } else {
                                return setError({
                                  ...error,
                                  name: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-12 withdrawal-input border-0 mt-2">
                          <Input
                            label={"Email"}
                            name={"email"}
                            value={email}
                            type={"text"}
                            errorMessage={error.email && error.email}
                            placeholder={"Enter Detail...."}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  email: `Email Is Required`,
                                });
                              } else {
                                return setError({
                                  ...error,
                                  email: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-12 d-flex mt-3 justify-content-end">
                          <Button
                            btnName={"Submit"}
                            type={"button"}
                            onClick={handleEditProfile}
                            newClass={"submit-btn"}
                            style={{
                              borderRadius: "0.5rem",
                              width: "88px",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-12">
              <div className="mb-4">
                <div className="withdrawal-box payment-box">
                  <h6 className="mb-0">Change Password</h6>
                  <div className="row">
                    <form>
                      <div className="row">
                        <div className="col-12 withdrawal-input">
                          <Input
                            label={"Old Password"}
                            name={"oldPassword"}
                            value={oldPassword}
                            type={"password"}
                            errorMessage={
                              error.oldPassword && error.oldPassword
                            }
                            placeholder={"Enter Old Password"}
                            onChange={(e) => {
                              setOldPassword(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  oldPassword: `OldPassword Is Required`,
                                });
                              } else {
                                return setError({
                                  ...error,
                                  oldPassword: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 withdrawal-input border-0 mt-2">
                          <Input
                            label={"New Password"}
                            name={"newPassword"}
                            value={newPassword}
                            errorMessage={
                              error.newPassword && error.newPassword
                            }
                            type={"password"}
                            placeholder={"Enter New Password"}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  newPassword: `New Password Is Required`,
                                });
                              } else {
                                return setError({
                                  ...error,
                                  newPassword: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 withdrawal-input border-0 mt-2">
                          <Input
                            label={"Confirm Password"}
                            name={"confirmPassword"}
                            value={confirmPassword}
                            className={`form-control`}
                            type={"password"}
                            errorMessage={
                              error.confirmPassword && error.confirmPassword
                            }
                            placeholder={"Enter Confirm Password"}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              if (!e.target.value) {
                                return setError({
                                  ...error,
                                  confirmPassword: `Confirm Password Is Required`,
                                });
                              } else {
                                return setError({
                                  ...error,
                                  confirmPassword: "",
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="col-12 d-flex mt-3 justify-content-end">
                          <Button
                            btnName={"Submit"}
                            type={"button"}
                            onClick={handlePassword}
                            newClass={"submit-btn"}
                            style={{
                              borderRadius: "0.5rem",
                              width: "88px",
                              marginLeft: "10px",
                            }}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Profile;
