import axios from "axios"
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { DangerRight, Success } from "../../Component/api/toastServices"




const initialState = {
    frame: [],
    isLoading: false,
    isSkeleton: false,
    total: null,
}


export const getFrame = createAsyncThunk("admin/avtarFrame/getbyadmin",async (payload)=>{
    return  apiInstanceFetch.get(`admin/avtarFrame/getbyadmin?startDate=${payload?.startDate}&endDate=${payload?.endDate}`)
  })
  export const addFrame = createAsyncThunk("admin/avtarFrame/create",async (payload)=>{
      
      return  apiInstance.post(`admin/avtarFrame/create`,payload)
    })
//   export const updateFrame = createAsyncThunk("admin/post/updatefakePost",async (payload)=>{
//       
//       return  axios.patch(`admin/post/updatefakePost?userId=${payload?.fakeUserId}&postId=${payload?.mongoId}`,payload?.formData)
//     })
  
    export const deleteFrame = createAsyncThunk("admin/avtarFrame/delete",async (payload)=>{
      
      return  apiInstance.delete(`admin/avtarFrame/delete?avtarFrameId=${payload}`)
    })
    

    const avtarFrameSlice = createSlice({
        name:"avtarFrameSlice",
        initialState,
        reducers:{},
        extraReducers: (builder)=>{
    
            builder.addCase(getFrame.pending,(state,action)=>{
                state.isLoading = true;
            })
    
            builder.addCase(getFrame.fulfilled, (state,action) => {
                state.frame = action.payload.avtarFrame;
                state.total = action?.payload?.totalavtarFrame
                state.isLoading = false;
            })
    
            builder.addCase(getFrame.rejected,(state,action)=>{
                state.isLoading = false;
            })
    
            builder.addCase(addFrame.pending,(state,action)=>{
                state.isLoading = true;
            })
    
            builder.addCase(addFrame.fulfilled, (state,action) => {
                
                if (action.payload.status) {
                  
                    state.frame.unshift(action?.payload?.avtarFrame);
                
                    Success("Frame Add Successfully")
                  }else{
                    DangerRight(action.payload.data.message)
                  }
                    state.isLoading = false;
            })
    
            builder.addCase(addFrame.rejected,(state,action)=>{
                state.isLoading = false;
            })
           
    
            builder.addCase(deleteFrame.pending, (state, action) => {
                state.isLoading = true;
              })
              builder.addCase(deleteFrame.fulfilled, (state, action) => {
                if (action.payload.status) {
                  state.frame = state.frame.filter((frame) => frame._id !== action.meta.arg);
                  Success("Frame Delete Successfully")
                }
                state.isLoading = false;
    
              })
              builder.addCase(deleteFrame.rejected, (state, action) => {
                state.isLoading = false;
              })
        }
    
    })
    export default avtarFrameSlice.reducer;