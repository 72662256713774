import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "../src/assets/css/style.css";
import "../src/assets/css/default.css";
import "../src/assets/css/custom.css";
import "../src/assets/css/dateRange.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./redux/store";
import { baseURL, key } from "./Component/utils/config";
import Loader from "./Component/utils/Loader";
// import Loader from "./Component/util/Loader";

axios.defaults.baseURL = baseURL;

axios.defaults.headers.common["key"] = key;


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <App />
      <Loader />
      <ToastContainer style={{ zIndex: "99999999" }} />
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
