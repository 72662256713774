import React, { useEffect, useState } from "react";
//material-ui
import IconButton from "@material-ui/core/IconButton";
import {  makeStyles, useTheme } from "@material-ui/core";

import {ReactComponent as FirstPageIcon} from '../../assets/icons/FirstPageIcon.svg'
import {ReactComponent as LastPageIcon} from '../../assets/icons/LastPageIcon.svg'
import { Box, FormControl, MenuItem,InputLabel, Select, } from "@mui/material";
import TablePagination from "react-js-pagination";
import Button from "./Button";
import Selector from "./Selector";

//useStyle
const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  pageNumber: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(2),
  },
  pageNumberButton: {
    border: "1px solid #C9C9C9",
    margin: theme.spacing(0, 0.5),
    borderRadius: "50px",
    cursor: "pointer",
    color: "#000",
    width:"45px",
    height:"45px",
    backgroundColor: "#fff",
  },
  activePageNumberButton: {
    backgroundColor: "#446CFA",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#f36278",
    },
  },
}));

const Pagination = (props) => {
  const [pages, setPages] = useState([]);

  const {
    customSelectDataShow,
    customSelectData,
    type,
    // server props
    setPage,
    userTotal,
    rowsPerPage,
    activePage,
    actionShow,
    // client props
    size,
    totalData,
    data,
    setData,
    // selector
    setActionPagination,
    actionPagination,
    paginationSubmitButton,
    setActivePage,
  } = props;

  const [clientData,setClientData]=useState()

  const handlePage = (pageNumber) => {
    setPage(pageNumber); 
  };


  const totalPages = Math.ceil(userTotal / rowsPerPage);

  return (
    <>
    {
      userTotal > rowsPerPage &&(
    <div className=" row gx-0 custom-pagination m-0 w-100 px-3">
      <>
    

                <div className="col-12 pagination d-flex flex-column  mt-3 mb-3" style={{marginRight:"10px"}}>
        {
           (type === "server" && userTotal > rowsPerPage) &&(
        <>
          <div>
          <p style={{marginBottom:"0px",color:"1F1F1F",fontSize:"16px",fontWeight:"500"}}>Showing {activePage} out of {totalPages} pages</p>
            <TablePagination
            activePage={activePage}
            itemsCountPerPage={rowsPerPage}
            totalItemsCount={userTotal}
            pageRangeDisplayed={3}
            onChange={(page) => handlePage(page)}
            itemClass="page-item"
          />
          </div>
          </>
          )
        }
       <div>
       {
            (type === "client" && userTotal > rowsPerPage) &&(
            <>
            <p style={{marginBottom:"0px",color:"1F1F1F",fontSize:"16px",fontWeight:"500"}}>Showing {activePage} out of {totalPages} pages</p>
             <TablePagination
             activePage={activePage}
             itemsCountPerPage={rowsPerPage}
             totalItemsCount={userTotal}
             pageRangeDisplayed={3}
             onChange={handlePage}
             itemClass="page-item"
           />
            </>
          )
        }
        </div>
        </div>
      </>
      
      
     
    </div>
      )
    }
    </>
  );
};
export default Pagination;

