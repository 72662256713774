import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstanceFetch } from "../../Component/api/axiosApi";

const initialState = {
  isLoading: false,
  dashData: {},
  chartData: [],
  chartAnalyticOfUsers: [],
  chartAnalyticOfPosts: [],
  chartAnalyticOfVideos: [],
  chartAnalyticOfActiveUser : [],
  chartAnalyticOfInActiveUser: []
};

export const getDashboardData = createAsyncThunk(
  "admin/dashboard/dashboardCount",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/dashboard/dashboardCount?startDate=${payload?.startDate}&endDate=${payload?.endDate}`
    );
  }
);

export const getChart = createAsyncThunk(
  "admin/dashboard/chartAnalytic",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/dashboard/chartAnalytic?startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=${payload?.type}`
    );
  }
);
export const getActiveUserChart = createAsyncThunk(
  "admin/dashboard/chartAnalyticOfUser",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/dashboard/chartAnalyticOfUser?startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=${payload?.type}`
    );
  }
);
const dashSlice = createSlice({
  name: "dashSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboardData.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.dashData = action?.payload?.data;
      state.isLoading = false;
    });

    builder.addCase(getDashboardData.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getChart.fulfilled, (state, action) => {
      (state.chartAnalyticOfUsers =
        action.payload.chartAnalyticOfUsers || state.chartAnalyticOfUsers),
        (state.chartAnalyticOfPosts =
          action.payload.chartAnalyticOfPosts || state.chartAnalyticOfPosts),
        (state.chartAnalyticOfVideos =
          action.payload.chartAnalyticOfVideos || state.chartAnalyticOfVideos),
        (state.isLoading = false);
    });

    builder.addCase(getChart.rejected, (state, action) => {
      state.isLoading = false;
      // DangerRight(action?.message)
    });
    builder.addCase(getActiveUserChart.fulfilled, (state, action) => {
      (state.chartAnalyticOfActiveUser =
        action.payload.data || state.chartAnalyticOfActiveUser),
      (state.chartAnalyticOfInActiveUser =
        action.payload.data || state.chartAnalyticOfInActiveUser),
       
        (state.isLoading = false);
    });

    builder.addCase(getActiveUserChart.rejected, (state, action) => {
      state.isLoading = false;
      
    });
  },
});
export default dashSlice.reducer;
