import { Box, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import Button from "../../extra/Button";
import { addFrame } from "../../../redux/slice/avtarFrameSlice";
import Input from "../../extra/Input";
import { permissionError } from "../../utils/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};

const CreateFrame = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const hasPermission = useSelector((state) => state.auth.admin.flag)
  const [addFrameOpen, setAddFrameOpen] = useState(false);
  const [mongoId, setMongoId] = useState("");
  const [image, setImage] = useState();
  const [imagePath, setImagePath] = useState();
  const [error, setError] = useState({
    image: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setAddFrameOpen(dialogue);
  }, [dialogue, dialogueData]);

  const handleFileUpload = async (event) => {
    
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }

    if (!event.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  const handleCloseAddCategory = () => {
    setAddFrameOpen(false);
    dispatch(closeDialog());
    localStorage.setItem("dialogueData", JSON.stringify(dialogueData));
  };

  const handleSubmit = () => {
    if(!hasPermission) return permissionError();
    if (!image) {
      let error = {};

      if (!image) error.image = "Image is required";
      return setError({ ...error });
    } else {
      let formData = new FormData();
      formData.append("avtarFrame", image);

      dispatch(addFrame(formData));
      dispatch(closeDialog());
    }
  };

  return (
    <div>
      <Modal
        open={addFrameOpen}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {dialogueData ? "Edit Frame" : "Add Frame"}
          </Typography>
          <form>
            <div className="row sound-add-box">
              <div className="col-12 mt-2">
                <Input
                  type={"file"}
                  label={"Avtar Image"}
                  accept={"image/*"}
                  errorMessage={error.image && error.image}
                  onChange={(e) => handleFileUpload(e)}
                />
              </div>
              <div className="col-12 d-flex">
                <div className="row">
                  {imagePath && (
                    <>
                      <img
                        height="150px"
                        width="150px"
                        alt="app"
                        src={imagePath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          // border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 pt-3 d-flex justify-content-end">
              <Button
                onClick={handleCloseAddCategory}
                btnName={"Close"}
                newClass={"close-model-btn"}
              />
              {dialogueData ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Update"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Submit"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateFrame;
