import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi";
import { DangerRight, Success } from "../../Component/api/toastServices";
import axios from "axios";

const initialState = {
    post: [],
    isLoading: false,
    isSkeleton: false,
    total: null,
}

export const allPost = createAsyncThunk("admin/post/getPosts",async (payload)=>{
  return  apiInstanceFetch.get(`admin/post/getPosts?start=${payload?.start}&limit=${payload?.limit}&startDate=${payload?.startDate}&endDate=${payload?.endDate}&type=${payload?.type}`)
})
export const addFakePost = createAsyncThunk("admin/post/uploadfakePost",async (payload)=>{
    
    return  apiInstance.post(`admin/post/uploadfakePost?userId=${payload?.fakeUserId}`,payload?.formData)
  })
export const updateFakePost = createAsyncThunk("admin/post/updatefakePost",async (payload)=>{
    
    return  apiInstance.patch(`admin/post/updatefakePost?userId=${payload?.fakeUserId}&postId=${payload?.mongoId}`,payload?.formData)
  })

  export const deleteFakePost = createAsyncThunk("admin/post/deletePost",async (payload)=>{
    
    return  apiInstance.delete(`admin/post/deletePost?postId=${payload}`)
  })
  

const postSlice = createSlice({
    name:"postSlice",
    initialState,
    reducers:{},
    extraReducers: (builder)=>{

        builder.addCase(allPost.pending,(state,action)=>{
            state.isSkeleton = true;
        })

        builder.addCase(allPost.fulfilled, (state,action) => {
            state.post = action.payload.post;
            state.total = action?.payload?.totalPost
            state.isSkeleton = false;
        })

        builder.addCase(allPost.rejected,(state,action)=>{
            state.isSkeleton = false;
        })

        builder.addCase(addFakePost.pending,(state,action)=>{
            state.isLoading = true;
        })

        builder.addCase(addFakePost.fulfilled, (state,action) => {
            
            if (action.payload.status) {
                state.post.unshift(action.payload.post);
                Success("Post Add Successfully")
              }
                state.isLoading = false;
        })

        builder.addCase(addFakePost.rejected,(state,action)=>{
            state.isLoading = false;
        })
        builder.addCase(updateFakePost.pending,(state,action)=>{
            state.isLoading = true;
        })

        builder.addCase(updateFakePost.fulfilled, (state,action) => {
            if (action.payload.status) {
                
                const postIndex = state.post.findIndex((post) => post?._id === action?.payload?.post?._id);
                if (postIndex !== -1) {
                  state.post[postIndex] = { ...state.post[postIndex], ...action.payload.post };
                }
                Success("Post Update Successfully")
              }
              else{
                
                DangerRight(action.payload.message)
              }
              state.isLoading = false;
  
        })

        builder.addCase(updateFakePost.rejected,(state,action)=>{
            state.isLoading = false;
        })

        builder.addCase(deleteFakePost.pending, (state, action) => {
            state.isLoading = true;
          })
          builder.addCase(deleteFakePost.fulfilled, (state, action) => {
            if (action.payload.status) {
              state.post = state.post.filter((post) => post._id !== action.meta.arg);
              Success("Post Delete Successfully")
            }
            state.isLoading = false;

          })
          builder.addCase(deleteFakePost.rejected, (state, action) => {
            state.isLoading = false;
          })
    }

})
export default postSlice.reducer;