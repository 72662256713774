import React, { useState } from "react";
import NewTitle from "../../extra/Title";
import Song from "./Song";
import SongCategory from "./SongCategory";
import { useSelector } from "react-redux";
import CreateSongCategory from "./CreateSongCategory";
import CreateSong from "./CreateSong";

export default function ManageSong() {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [dayAnalytics, setDayAnalytics] = useState("today");
  const [multiButtonSelect, setMultiButtonSelect] = useState("Song List");

  return (
    <div className="userPage channelPage">
      {dialogue && dialogueType == "addSongCategory" && <CreateSongCategory />}
      {dialogue && dialogueType == "addSong" && <CreateSong />}
      <div>
        <div className="dashboardHeader primeHeader mb-3 p-0">
          <NewTitle
            dayAnalyticsShow={false}
            name={`Song`}
            titleShow={true}
            multiButtonSelect={multiButtonSelect}
            setMultiButtonSelect={setMultiButtonSelect}
            labelData={["Song List", "Song Category"]}
          />
        </div>
     
      </div>
      {multiButtonSelect == "Song List" && <Song />}
      {multiButtonSelect == "Song Category" && <SongCategory />}
    </div>
  );
}
