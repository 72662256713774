import React, { useEffect, useState } from "react";
import NewTitle from "../../extra/Title";
import Searching from "../../extra/Searching";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReport,
  getPostReport,
  solvedReport,
} from "../../../redux/slice/reportSlice";
import dayjs from "dayjs";
import Button from "../../extra/Button";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import { ReactComponent as TrueArrow } from "../../../assets/icons/TrueArrow.svg";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { permissionError, warning } from "../../utils/Alert";

const PostReport = (props) => {
  const { report, total } = useSelector((state) => state.report);
  const { startDate, endDate, multiButtonSelectData } = props;
  const dispatch = useDispatch();
  const hasPermission = useSelector((state) => state.auth.admin.flag)

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(20);
  const [type, setType] = useState(1);
  const [selectCheckData, setSelectCheckData] = useState([]);
  const [search, setSearch] = useState();

  useEffect(() => {
    let payload = {
      stateDate: startDate,
      endDate: endDate,
      type: 1,
    };
    dispatch(getPostReport(payload));
  }, [dispatch]);

  useEffect(() => {
    setData(report);
  }, [report]);

  const postReportTable = [
    {
      Header: "NO",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">
          {(page - 1) * size + parseInt(index) + 1}
        </span>
      ),
    },
    {
      Header: "VIDEO ID",
      body: "uniqueVideoId",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.postUniqueId}</span>
      ),
    },
    {
      Header: "VIDEO IMAGE",
      body: "image",
      Cell: ({ row, index }) => (
        <img
          src={row?.postImage}
          width="48px"
          height="48px"
          style={{ objectFit: "cover" }}
          onError={(e) => {
            e.target.src = UserImage;
          }}
        />
      ),
    },

    {
      Header: "USER",
      body: "userName",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.userName}</span>
      ),
    },
    {
      Header: "POST REPORT TYPE",
      body: "reportType",
      Cell: ({ row }) => (
        <>
          {row?.reportType === 1 && (
            <span className="text-capitalize">SEXUAL</span>
          )}
          {row?.reportType === 2 && (
            <span className="text-capitalize">VIOLENT_OR_REPULSIVE</span>
          )}
          {row?.reportType === 3 && (
            <span className="text-capitalize">HATEFUL_OR_ABUSIVE</span>
          )}
          {row?.reportType === 4 && (
            <span className="text-capitalize">HARMFUL_OR_DANGEROUS</span>
          )}
          {row?.reportType === 5 && (
            <span className="text-capitalize">SPAM_OR_MISLEADING</span>
          )}
          {row?.reportType === 6 && (
            <span className="text-capitalize">CHILD_ABUSE</span>
          )}
          {row?.reportType === 7 && (
            <span className="text-capitalize">OTHERS</span>
          )}
        </>
      ),
    },
    {
      Header: "STATUS",
      body: "status",
      Cell: ({ row }) => (
        <>
          {row?.status === 1 && (
            <span className="text-capitalize badge badge-primary p-2">
              Pending
            </span>
          )}
          {row?.status === 2 && (
            <span className="text-capitalize badge badge-success p-2">
              Solved
            </span>
          )}
        </>
      ),
    },
    {
      Header: "VIDEO REPORTED",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMMM YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "ACTION",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          {row.status === 2 ? (
            ""
          ) : (
            <Button
              btnIcon={<TrueArrow />}
              onClick={() => handleSolved(row?._id)}
            />
          )}
          <Button
            btnIcon={<TrashIcon />}
            onClick={() => handleDeleteReport(row)}
          />
        </div>
      ),
    },
  ];

  const selectType = (type) => {
    
    let payload = {
      startDate: "All",
      endDate: "All",
      type: type,
    };
    setType(type);
    dispatch(getPostReport(payload));
  };

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };

  const handleSolved = (id) => {
   if (!hasPermission) return permissionError();
    
    dispatch(solvedReport(id));
  };

  const handleDeleteReport = (row) => {
   if (!hasPermission) return permissionError();

    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteReport(row?._id));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="userPage p-0">
        <div className="dashboardHeader primeHeader mb-3 p-0">
         
        </div>
        <div className="payment-setting-box user-table ">
          <div className="row" style={{ padding: "12px" }}>
            <div className="col-md-6 col-12 mt-3">
              <h5
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginBottom: "5px",
                  marginTop: "5px",
                  padding: "3px",
                }}
              >
                Post Report Table
              </h5>
            </div>

            <div className="col-md-6 col-12 mt-3 d-flex justify-content-end pe-3">
              <FormControl>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{
                    fontWeight: "500",
                    fontSize: "17px",
                    marginBottom: "10px",
                  }}
                >
                  Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={type}
                  label="Type"
                  onChange={(e) => selectType(e.target.value)}
                >
                  <MenuItem value="1" key="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem value="2" key="Solved">
                    Solved
                  </MenuItem>
                  <MenuItem value="All" key="All">
                    All
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="mt-3">
            <Table
              data={data}
              mapData={postReportTable}
              serverPerPage={size}
              serverPage={page}
              type={"server"}
            />
            <div className="mt-3">
              <Pagination
                type={"server"}
                activePage={page}
                rowsPerPage={size}
                userTotal={total}
                setPage={setPage}
                handleRowsPerPage={handleRowsPerPage}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostReport;
