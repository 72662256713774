import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Selector from "../../extra/Selector";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactAudioPlayer from "react-audio-player";
import SmallLoader from "../../extra/SmallLoader";
import { allUsers, getCountry } from "../../../redux/slice/userSlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import ReactDropzone from "react-dropzone";
import { addFakePost, updateFakePost } from "../../../redux/slice/postSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};

const CreateFakePost = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const { user, historyData } = useSelector((state) => state.user);

  const [mongoId, setMongoId] = useState("");
  const [addPostOpen, setAddPostOpen] = useState(false);
  const [caption, setCaption] = useState("");
  const [location, setLocation] = useState("");
  const [countryDataSelect, setCountryDataSelect] = useState();
  const [countryData, setCountryData] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [fakeUserId, setFakeUserId] = useState();

  const [fakeUserData, setFakeUserData] = useState([]);

  const [images, setImages] = useState([]);
  const [error, setError] = useState({
    caption: "",
    images: "",
    fakeUserId: "",
    country: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    setAddPostOpen(dialogue);
    if (dialogueData) {
      
      setMongoId(dialogueData?._id);
      setCaption(dialogueData?.caption);
      setCountryDataSelect(dialogueData?.location);
      setFakeUserId(dialogueData?.userId);
      setImages(dialogueData?.postImage);
    }
  }, [dialogue, dialogueData]);
  useEffect(() => {
    const payload = {
      type: "fakeUser",
      start: 1,
      limit: 100,
      startDate: "All",
      endDate: "All",
    };
    dispatch(allUsers(payload));
    dispatch(getCountry());
  }, [dispatch]);

  useEffect(() => {
    setFakeUserData(user);
  }, [user]);

  useEffect(() => {
    const countryName = historyData?.map((item) => item?.name?.common);
    setCountryData(countryName);
    const countryDataSelectData = historyData.filter((item) => {
      const countrySelect = item?.name?.common;
      return countrySelect?.toLowerCase() === countryDataSelect;
    });
    if (countryDataSelectData?.length > 0) {
      const getLatitude = countryDataSelectData?.map((item) => {
        setLatitude(item?.latlng[0]);
        setLongitude(item?.latlng[1]);
      });
    }
  }, [historyData, countryDataSelect]);

  const onPreviewDrop = async (acceptedFiles) => {
    
    acceptedFiles.forEach((file, index) => {
      Object.assign(file, { preview: URL.createObjectURL(file) });
      setImages(images?.concat(acceptedFiles));
    });

    if (!acceptedFiles || acceptedFiles.length === 0) {
      
      setError({
        ...error,
        images: "Image Is Required",
      });
    } else {
      setError({
        ...error,
        images: "",
      });
    }
  };

  const removeImage = (file) => {
    if (file.preview) {
      const updatedImages = images.filter(
        (ele) => ele.preview !== file.preview
      );
      setImages(updatedImages);
    } else {
      const updatedImages = images.filter((ele) => ele !== file);
      setImages(updatedImages);
    }
  };
  const handleCloseAddCategory = () => {
    setAddPostOpen(false);
    dispatch(closeDialog());
  };

  const handleSubmit = () => {
      
    if (
      !caption ||
      !fakeUserId ||
      images?.length === 0 ||
      images?.length > 5 ||
      !countryDataSelect
    ) {
      let error = {};
      if (!caption) error.caption = "Caption Is Required !";
      if (!fakeUserId) error.fakeUserId = "User Is Required !";
      if (!countryDataSelect) error.country = "Country is required !";
      if (images?.length === 0) error.images = "Please select an Image!";
      if (images?.length > 5) error.images = "Please select maximum 5 Image!";
      return setError({ ...error });
    } else {
      
      let formData = new FormData();

      formData.append("caption", caption);
      for (let i = 0; i < images?.length; i++) {
        formData.append("postImage", images[i]);
      }
      formData.append("location", countryDataSelect);
      formData.append("latitude", latitude);
      formData.append("longitude", longitude);

      if (mongoId) {
        
        let payload = { formData, fakeUserId, mongoId };
        dispatch(updateFakePost(payload));
      } else {
        
        let payload = { formData, fakeUserId };
        dispatch(addFakePost(payload));
      }
      dispatch(closeDialog());
    }
  };


  return (
    <div>
      <Modal
        open={addPostOpen}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {dialogueData ? "Edit Post" : "Add Post"}
          </Typography>
          <form>
            <div className="row sound-add-box">
              {dialogueData ? (
                ""
              ) : (
                <div className="col-12 col-lg-6 col-sm-6  mt-2 country-dropdown">
                  <Selector
                    label={"Fake User"}
                    selectValue={fakeUserId}
                    placeholder={"Enter Details..."}
                    selectData={fakeUserData}
                    selectId={true}
                    errorMessage={error.fakeUserId && error.fakeUserId}
                    onChange={(e) => {
                      setFakeUserId(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          fakeUserId: `Fake User Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          fakeUserId: "",
                        });
                      }
                    }}
                  />
                </div>
              )}
              <div className="col-lg-6 col-sm-12">
                <Input
                  label={"Caption"}
                  name={"caption"}
                  placeholder={"Enter Details..."}
                  value={caption}
                  errorMessage={error.caption && error.caption}
                  onChange={(e) => {
                    setCaption(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        caption: `Caption Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        caption: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2 country-dropdown">
                <Selector
                  label={"Country"}
                  selectValue={countryDataSelect}
                  placeholder={"Enter Details..."}
                  selectData={countryData}
                  errorMessage={error.country && error.country}
                  onChange={(e) => {
                    setCountryDataSelect(e.target.value);
                    if (!e.target.value && !dialogueData) {
                      return setError({
                        ...error,
                        country: `Country Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        country: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2">
                <Input
                  label={"Latitude"}
                  name={"latitude"}
                  disabled={true}
                  value={latitude}
                  placeholder={"Country Select"}
                />
              </div>
              <div className="col-12 col-lg-6 col-sm-6  mt-2">
                <Input
                  label={"Longitude"}
                  disabled={true}
                  name={"longitude"}
                  value={longitude}
                  placeholder={"Country Select"}
                />
              </div>
              <div className="col-12 mt-2">
                <div className="custom-input">
                  <label htmlFor="">Images</label>
                  <>
                    <ReactDropzone
                      onDrop={(acceptedFiles) => onPreviewDrop(acceptedFiles)}
                      accept="image/*"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="mt-4">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div
                              style={{
                                height: "130px",
                                width: "130px",
                                border: "2px dashed gray",
                                textAlign: "center",
                                marginTop: "10px",
                              }}
                            >
                              <i
                                className="fas fa-plus"
                                style={{
                                  paddingTop: "30px",
                                  fontSize: "70px",
                                }}
                              ></i>
                            </div>
                          </div>
                        </section>
                      )}
                    </ReactDropzone>

                    {error.images && (
                      <div className="ml-2 mt-1">
                        {error.images && (
                          <div className="pl-1 text__left">
                            <span className="text-red">{error.images}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                </div>
              </div>
              <div className="col-12 d-flex">
                <div className="d-flex">
                  {images?.length > 0 && (
                    <>
                      {images?.map((file, index) => {
                        return (
                          <div key={index} className="">
                            <div>
                              <img
                                src={file?.preview ? file?.preview : file}
                                alt=""
                                className="mt-3 ms-3 rounded float-left mb-2"
                                height="100px"
                                width="100px"
                              />
                              <div
                                className="img-container"
                                style={{
                                  display: "inline",
                                  position: "relative",
                                  float: "left",
                                }}
                              >
                                <i
                                  className="fas fa-times-circle text-danger"
                                  style={{
                                    position: "absolute",
                                    left: "106px",
                                    top: "10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => removeImage(file)}
                                ></i>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 pt-3 d-flex justify-content-end">
              <Button
                onClick={handleCloseAddCategory}
                btnName={"Close"}
                newClass={"close-model-btn"}
              />
              {dialogueData ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Update"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Submit"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export default CreateFakePost;
