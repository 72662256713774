import React, { useEffect, useState } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { closeDialog } from "../../../redux/slice/dialogueSlice";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../extra/Input";
import Button from "../../extra/Button";
import {
  addSongCategory,
  updateSongCategory,
} from "../../../redux/slice/songSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "13px",
  border: "1px solid #C9C9C9",
  boxShadow: 24,
  p: "19px",
};

const CreateSongCategory = () => {
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();
  const [addCategoryOpen, setAddCategoryOpen] = useState(false);
  const [mongoId, setMongoId] = useState("");
  const [image, setImage] = useState([]);
  const [name, setName] = useState();
  const [imagePath, setImagePath] = useState();
  const [error, setError] = useState({
    image: "",
    name: "",
  });

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?._id);
      setName(dialogueData?.name);
      setImagePath(dialogueData?.image);
    }
    setAddCategoryOpen(dialogue);
  }, [dialogue, dialogueData]);

  const handleCloseAddCategory = () => {
    setAddCategoryOpen(false);
    dispatch(closeDialog());
    localStorage.setItem("dialogueData", JSON.stringify(dialogueData));
  };

  const handleFileUpload = async (event) => {
    if (event.target.files[0]) {
      setImage(event.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(event.target.files[0]);
    }

    if (!event.target.files[0]) {
      return setError({
        ...error,
        image: `Image Is Required`,
      });
    } else {
      return setError({
        ...error,
        image: "",
      });
    }
  };

  const handleSubmit = () => {
    
    if (!name || !image) {
      let error = {};
      if (!name) error.name = "Name is required";
      if (!image) error.image = "Image is required";
      return setError({ ...error });
    } else {
      
      let formData = new FormData();
      formData.append("name", name);
      formData.append("image", image);

      if (mongoId) {
        
        let payload = { formData, mongoId };
        dispatch(updateSongCategory(payload));
      } else {
        
        dispatch(addSongCategory(formData));
      }

      dispatch(closeDialog());
    }
  };

  return (
    <div>
      <Modal
        open={addCategoryOpen}
        onClose={handleCloseAddCategory}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="create-channel-model">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {dialogueData ? "Edit Frame" : "Add Frame"}
          </Typography>
          <form>
            <div className="row sound-add-box">
              <div className="col-12 mt-2">
                <Input
                  label={"Name"}
                  name={"name"}
                  placeholder={"Enter Name..."}
                  value={name}
                  errorMessage={error.name && error.name}
                  onChange={(e) => {
                    setName(e.target.value);
                    if (!e.target.value) {
                      return setError({
                        ...error,
                        name: `Name Is Required`,
                      });
                    } else {
                      return setError({
                        ...error,
                        name: "",
                      });
                    }
                  }}
                />
              </div>
              <div className="col-12 mt-2">
                <Input
                  type={"file"}
                  label={"Sound Image"}
                  accept={"image/*"}
                  errorMessage={error.image && error.image}
                  onChange={handleFileUpload}
                />
              </div>
              <div className="col-12 d-flex">
                <div className="row">
                  {imagePath && (
                    <>
                      <img
                        height="150px"
                        width="150px"
                        alt="app"
                        src={imagePath}
                        style={{
                          boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                          // border: "2px solid #fff",
                          borderRadius: 10,
                          marginTop: 10,
                          float: "left",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3 pt-3 d-flex justify-content-end">
              <Button
                onClick={handleCloseAddCategory}
                btnName={"Close"}
                newClass={"close-model-btn"}
              />
              {dialogueData ? (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Update"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              ) : (
                <>
                  <Button
                    onClick={handleSubmit}
                    btnName={"Submit"}
                    type={"button"}
                    newClass={"submit-btn-dialogue"}
                    style={{
                      borderRadius: "0.5rem",
                      width: "88px",
                      marginLeft: "10px",
                    }}
                  />
                </>
              )}
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default CreateSongCategory;
