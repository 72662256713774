import React, { useEffect, useState } from "react";
import CreateSongCategory from "./CreateSongCategory";
import Button from "../../extra/Button";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditBtn.svg";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { permissionError, warning } from "../../utils/Alert";
import dayjs from "dayjs";
import { deleteSong, getSong } from "../../../redux/slice/songSlice";
import CreateSong from "./CreateSong";
import ReactAudioPlayer from "react-audio-player";
const Song = () => {
  const { song, totalSong } = useSelector((state) => state.song);
  const hasPermission = useSelector((state) => state.auth.admin.flag)

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    let payload = {
      start: page,
      limit: size,
    };
    dispatch(getSong(payload));
  }, [dispatch]);

  useEffect(() => {
    setData(song);
  }, [song]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    if (!hasPermission) return permissionError();
    setSelectAllChecked(checked);
    if (checked) {
      setSelectCheckData([...data]);
    } else {
      setSelectCheckData([]);
    }
  };

  const songTable = [
    {
      Header: "NO",
      body: "no",
      Cell: ({ index }) => (
        <span className="  text-nowrap">{(page - 1) * size + index + 1}</span>
      ),
    },
    {
      Header: "IMAGE",
      body: "songImage",
      Cell: ({ row, index }) => (
        <img src={row?.songImage} width="40px" height="40px" />
      ),
    },
    {
      Header: "SINGER NAME",
      body: "singerName",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.singerName}</span>
      ),
    },
    {
      Header: "SONG CATEGORY",
      body: "songCategory",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.songCategoryId?.name}</span>
      ),
    },
    {
      Header: "SONG",
      body: "songLink",
      Cell: ({ row, index }) => (
        <ReactAudioPlayer
          src={row?.songLink}
          controls
          muted
          onPlay={() => console.log("Audio is playing")}
          onError={(error) => console.error("Audio error:", error)}
        />
      ),
    },
    {
      Header: "SONG TITLE",
      body: "songTitle",
      Cell: ({ row }) => (
        <span className="text-capitalize">{row?.songTitle}</span>
      ),
    },

    {
      Header: "ACTION",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <Button
            btnIcon={<EditIcon />}
            onClick={() => {
              dispatch(openDialog({ type: "addSong", data: row }));
            }}
          />
          <Button
            btnIcon={<TrashIcon />}
            onClick={() => handleDeleteCategory(row)}
          />
        </div>
      ),
    },
  ];

  const handleDeleteCategory = (row) => {
   if (!hasPermission) return permissionError();

    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteSong(row?._id));
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div className="user-table">
        <div className="user-table-top">
          <div className="row align-items-start">
            <div className="col-6">
              <h5
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginBottom: "15px",
                }}
              >
                Song 
              </h5>
            </div>
            <div
              className="col-6 new-fake-btn d-flex justify-content-end"
              style={{ marginTop: "5px" }}
            >
              <Button
                btnIcon={<AddIcon />}
                btnName={"New"}
                newClass={"rounded"}
                onClick={() => {
                  dispatch(openDialog({ type: "addSong" }));
                }}
              />
            </div>
          </div>
        </div>
        <Table
          data={data}
          mapData={songTable}
          serverPerPage={size}
          serverPage={page}
          handleSelectAll={handleSelectAll}
          selectAllChecked={selectAllChecked}
          type={"server"}
        />
        <div className="mt-3">
          <Pagination
            type={"server"}
            activePage={page}
            rowsPerPage={size}
            userTotal={totalSong}
            setPage={setPage}
            handleRowsPerPage={handleRowsPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Song;
