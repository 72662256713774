import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/MetubeLogo.png";
import { getAdmin } from "../../redux/slice/authSlice";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notificationIcon.svg";
import UserImage from "../../assets/images/noimage.png"

const Navbar = () => {
  const { admin } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAdmin());
  }, [dispatch]);

  return (
    <>
      <div className="mainNavbar webNav me-4">
        <div className="row">
          <div className="navBox " style={{ paddingTop: "8px" }}>
            <div
              className="navBar boxBetween px-4 "
              style={{ padding: "10px 0px" }}
            >
              <div className="navToggle" id={"toggle"}>
                <i class="fa-solid fa-bars text-white cursor"></i>
              </div>
              <div className=""></div>
              <div className="col-4 logo-show-nav">
                <div className="sideBarLogo boxCenter">
                  <Link
                    to={"/admin/adminDashboard"}
                    className="d-flex align-items-center"
                  >
                    <img src={Logo} alt="" width={"40px"} />
                    <span className="fs-3 fw-bold text-black">Socially</span>
                  </Link>
                </div>
              </div>
              <div className="col-7">
                <div className="navIcons d-flex align-items-center justify-content-end">
                  <div
                    className="pe-4 cursor"
                    style={{ backgroundColor: "inherit", position: "relative" }}
                  >
                    <NotificationIcon width="20px" height="25px" />
                  </div>
                  <div className="pe-4" style={{ backgroundColor: "inherit" }}>
                    <Link
                      to="/admin/adminProfile"
                      style={{ backgroundColor: "inherit" }}
                    >
                      <img
                        src={admin?.image}
                        alt=""
                        width={`50px`}
                        height={`50px`}
                        style={{
                          borderRadius: "15px",
                          border: "1px solid white",
                          objectFit: "cover",
                        }}
                        onError={(e) => {
                          e.target.src = UserImage;
                        }}
                        className="cursor"
                      />
                    </Link>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        textTransform: "capitalize",
                        marginLeft : "10px"
                      }}
                    >
                      {admin?.name}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
