import "./App.css";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Admin from "./Component/Pages/Admin";
import Login from "./Component/Pages/Login";
import { useDispatch } from "react-redux";
import "./assets/css/responsive.css";
import { setToken } from "./Component/utils/setAuth";
import AuthRoute from "./Component/utils/authRoute";
import { setOldAdmin } from "./redux/slice/authSlice";
import Error404 from "./Component/Pages/Error404";
import { IdleTimeoutManager } from "idle-timer-manager";
import ForgotPassword from "./Component/Pages/ForgotPassword";

function App() {
  const dispatch = useDispatch();
  const key = localStorage.getItem("key");
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   if (!token && !key) return;
  //   dispatch(setOldAdmin(token))
  // }, [setToken, key]);

  const navigate = useNavigate();

  const isAuth = true;

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: 1800, //expired after 1800 secs (30 min)
      onExpired: (time) => {
        navigate("/")
      },
    });
    return () => {
      manager.clear();
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Error404 />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route element={<AuthRoute />}>
          <Route path="/admin/*" element={<Admin />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
