import { useEffect, useState } from "react";
import Button from "../extra/Button";
import Input from "../extra/Input";
import Logo from "../../assets/images/logo.svg";
import LogoImg from "../../assets/images/imageLogo.png";
import { useLocation, useNavigate } from "react-router-dom";

import LoginImg from "../../assets/images/loginPage.png";

import { connect, useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slice/authSlice";

const Login = (props) => {
  let navigate = useNavigate();

  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    isAuth && navigate("/admin");
  }, [isAuth]);

  const [email, setEmail] = useState("demo@admin.com");
  const [password, setPassword] = useState("123456");
  const [demoLogin, setDemoLogin] = useState();

  const [error, setError] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (demoLogin) {
      setEmail("demo@admin.com");
      setPassword("123456");
      setError({
        email: "",
        password: "",
      });
      setDemoLogin(false); // Reset demoLogin state
    }
  }, [demoLogin]);

  const handleDemoLogin = () => {
    const details = {
      email: "demo@admin.com",
      password: "123456",
    };

    dispatch(login(details));
  };
  const handleSubmit = () => {
    if (!email || !password) {
      let error = {};
      if (!email) error.email = "Email Is Required !";
      if (!password) error.password = "password is required !";
      return setError({ ...error });
    } else {
      let loginDetails = {
        email,
        password,
      };

      dispatch(login(loginDetails));
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <div className="login-page-content">
        <div className="bg-login">
          <div className="login-page-box">
            <div className="row">
              <div className="col-12 col-md-6 right-login-img position-relative">
                <img src={LoginImg} />

                <div className="position-absolute imageLogo">
                  <span className="d-flex justify-content-center align-items-center">
                    <img
                      src={LogoImg}
                      style={{ width: "300px" }}
                      alt=""
                      srcset=""
                    />
                  </span>
                  <span className="d-flex justify-content-center align-items-center mt-4">
                    <p
                      className="text-white"
                      style={{ fontWeight: "400", fontSize: "16px" }}
                    >
                      Our reputation is the proof.
                    </p>
                  </span>
                  <span className="d-flex justify-content-center align-items-center">
                    <h2
                      className="text-white"
                      style={{ fontWeight: "600", fontSize: "50px" }}
                    >
                      You dream it, We build it.
                    </h2>
                  </span>
                </div>
              </div>
              <div
                className="col-12 col-md-6 text-login"
                style={{ paddingRight: "128px", paddingTop: "10px",paddingLeft:"70px" }}
              >
                <div className="heading-login">
                  <img src={Logo} />
                </div>
                <div className="login-left-form">
                  <span>Welcome back !!!</span>
                  <h5 style={{ fontSize: "81px" }} className="mt-4 mb-5">
                    Sign in
                  </h5>
                  <Input
                    label={`Email`}
                    id={`loginEmail`}
                    type={`email`}
                    fieldClass={`py-4 mt-3 mb-2`}
                    value={email}
                    errorMessage={error.email && error.email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: `Email Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          email: "",
                        });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  <Input
                    label={`Password`}
                    id={`loginPassword`}
                    type={`password`}
                    fieldClass={`py-4 mt-3 mb-2 `}
                    newClass={`mt-4`}
                    value={password}
                    className={`form-control`}
                    errorMessage={error.password && error.password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          password: `Password Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          password: "",
                        });
                      }
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  <div
                    className="w-100 my-3"
                    onClick={() => navigate("/forgotPassword")}
                  >
                    <h4>Forgot Password ?</h4>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      btnName={"Demo Log In"}
                      newClass={"login-btn me-3 fs-6"}
                      onClick={handleDemoLogin}
                    />
                    <Button
                      btnName={"Log In"}
                      newClass={"login-btn ms-5 fs-6"}
                      onClick={handleSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
