import React, { useState } from "react";
import NewTitle from "../../extra/Title";
import Post from "./Post";
import FakePost from "./FakePost";
import CreateFakePost from "./CreateFakePost";
import { useSelector } from "react-redux";

export default function ManagePost() {

  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const [dayAnalytics, setDayAnalytics] = useState("today");
  const [multiButtonSelect, setMultiButtonSelect] = useState("Real Post");
  const [startDate, setStartDate] = useState("All");
  const [endDate, setEndDate] = useState("All");
  

  return (
    <div className="userPage channelPage">
      { dialogueType == "fakePost" && <CreateFakePost />}
      <div>
        <div className="dashboardHeader primeHeader mb-3 p-0">
          <NewTitle
            dayAnalyticsShow={true}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            startDate={startDate}
            endDate={endDate}
            name={`Post`}
            titleShow={true}
            multiButtonSelect={multiButtonSelect}
            setMultiButtonSelect={setMultiButtonSelect}
            labelData={["Real Post", "Fake Post"]}
          />
        </div>
      </div>
      {multiButtonSelect == "Real Post" && (
        <Post startDate={startDate} endDate={endDate} />
      )}
      {multiButtonSelect == "Fake Post" && (
        <FakePost startDate={startDate} endDate={endDate} />
      )}
    </div>
  );
}
