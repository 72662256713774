import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance, apiInstanceFetch } from "../../Component/api/axiosApi";
import { DangerRight, Success } from "../../Component/api/toastServices";
import axios from "axios";

const initialState = {
  song: [],
  songCategory: [],
  isLoading: false,
  isSkeleton: false,
  totalSong: null,
  totalSongCategory: null,
};

// song Api
export const getSong = createAsyncThunk(
  "admin/song/getsongs",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/song/getsongs?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);

export const addSong = createAsyncThunk(
  "admin/song/createSong",
  async (payload) => {
    return apiInstance.post(`admin/song/createSong`, payload);
  }
);

export const updateSong = createAsyncThunk(
  "admin/song/updateSong",
  async (payload) => {
    
    return apiInstance.patch(
      `admin/song/updateSong?songId=${payload?.mongoId}`,
      payload?.formData
    );
  }
);

export const deleteSong = createAsyncThunk(
  "admin/song/deletesong",
  async (payload) => {
    return apiInstance.delete(`admin/song/deletesong?songId=${payload}`);
  }
);

// song Category Api

export const getSongCategory = createAsyncThunk(
  "admin/songCategory/getSongCategory",
  async (payload) => {
    return apiInstanceFetch.get(
      `admin/songCategory/getSongCategory?start=${payload?.start}&limit=${payload?.limit}`
    );
  }
);


export const addSongCategory = createAsyncThunk(
    "admin/songCategory/create",
    async (payload) => {
      
      return apiInstance.post(`admin/songCategory/create`, payload);
    }
  );
  
  export const updateSongCategory = createAsyncThunk(
    "admin/songCategory/update",
    async (payload) => {
      
      return apiInstance.patch(
        `admin/songCategory/update?songCategoryId=${payload?.mongoId}`,
        payload?.formData
      );
    }
  );
  
  export const deleteSongCategory = createAsyncThunk(
    "admin/songCategory/delete",
    async (payload) => {
      return apiInstance.delete(`admin/songCategory/delete?songCategoryId=${payload}`);
    }
  );








const songSlice = createSlice({
  name: "songSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSong.pending, (state, action) => {
      state.isSkeleton = true;
    });

    builder.addCase(getSong.fulfilled, (state, action) => {
      state.song = action.payload.song;
      state.totalSong = action?.payload?.totalSong;
      state.isSkeleton = false;
    });

    builder.addCase(getSong.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    builder.addCase(addSong.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(addSong.fulfilled, (state, action) => {
      
      if (action.payload.status) {
        state.song.unshift(action.payload.songList);

        Success("Song Add Successfully");
      }
      state.isLoading = false;
    });

    builder.addCase(addSong.rejected, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateSong.pending,(state,action)=>{
        state.isLoading = true;
    })

    builder.addCase(updateSong.fulfilled, (state,action) => {
        
        
        if (action.payload.status) {
            
            const songIndex = state.song.findIndex((song) => song?._id === action?.payload?.song?._id);
            if (songIndex !== -1) {
              state.song[songIndex] = { ...state.song[songIndex], ...action.payload.song };
            }
            Success("Song Update Successfully")
          }
          else{
            
            DangerRight(action.payload.message)
          }
          state.isLoading = false;

    })

    builder.addCase(updateSong.rejected,(state,action)=>{
        state.isLoading = false;
    })

    builder.addCase(deleteSong.pending, (state, action) => {
        state.isLoading = true;
      })
      builder.addCase(deleteSong.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.song = state.song.filter((song) => song._id !== action.meta.arg);
          Success("Song Delete Successfully")
        }
        state.isLoading = false;

      })
      builder.addCase(deleteSong.rejected, (state, action) => {
        state.isLoading = false;
      })


    //   song Category Cases

    builder.addCase(getSongCategory.pending, (state, action) => {
        state.isSkeleton = true;
      });
  
      builder.addCase(getSongCategory.fulfilled, (state, action) => {
        state.songCategory = action.payload.songCategory;
        state.totalSongCategory = action?.payload?.totalSongCategory;
        state.isSkeleton = false;
      });
  
      builder.addCase(getSongCategory.rejected, (state, action) => {
        state.isSkeleton = false;
      });
  
      builder.addCase(addSongCategory.pending, (state, action) => {
        state.isLoading = true;
      });
  
      builder.addCase(addSongCategory.fulfilled, (state, action) => {
        
        if (action.payload.status) {
          state.songCategory.unshift(action.payload.songCategory);
  
          Success("Song Category Add Successfully");
        }
        state.isLoading = false;
      });
  
      builder.addCase(addSongCategory.rejected, (state, action) => {
        state.isLoading = false;
      });
  
      builder.addCase(updateSongCategory.pending,(state,action)=>{
          state.isLoading = true;
      })
  
      builder.addCase(updateSongCategory.fulfilled, (state,action) => {
          
          
          if (action.payload.status) {
              
              const songCategoryIndex = state.songCategory.findIndex((songCategory) => songCategory?._id === action?.payload?.songCategory?._id);
              if (songCategoryIndex !== -1) {
                state.song[songCategoryIndex] = { ...state.songCategory[songCategoryIndex], ...action.payload.songCategory };
              }
              Success("Song Category Update Successfully")
            }
            else{
              
              DangerRight(action.payload.message)
            }
            state.isLoading = false;
  
      })
  
      builder.addCase(updateSongCategory.rejected,(state,action)=>{
          state.isLoading = false;
      })
  
      builder.addCase(deleteSongCategory.pending, (state, action) => {
          state.isLoading = true;
        })
        builder.addCase(deleteSongCategory.fulfilled, (state, action) => {
          if (action.payload.status) {
            state.songCategory = state.songCategory.filter((songCategory) => songCategory._id !== action.meta.arg);
            Success("Song Category Delete Successfully")
          }
          state.isLoading = false;
  
        })
        builder.addCase(deleteSongCategory.rejected, (state, action) => {
          state.isLoading = false;
        })
  


  },
});

export default songSlice.reducer;