import React, { useEffect, useState } from "react";
import CreateSongCategory from "./CreateSongCategory";
import Button from "../../extra/Button";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trashIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/EditBtn.svg";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSongCategory,
  deleteSongCategry,
  getSongCategory,
} from "../../../redux/slice/songSlice";
import { openDialog } from "../../../redux/slice/dialogueSlice";
import Table from "../../extra/Table";
import Pagination from "../../extra/Pagination";
import { permissionError, warning } from "../../utils/Alert";
import dayjs from "dayjs";

const SongCategory = (props) => {
  const { songCategory, totalSongCategory } = useSelector(
    (state) => state.song
  );
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const hasPermission = useSelector((state) => state.auth.admin.flag);
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    let payload = {
      start: page,
      limit: size,
    };
    dispatch(getSongCategory(payload));
  }, [dispatch]);

  useEffect(() => {
    setData(songCategory);
  }, [songCategory]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const handleRowsPerPage = (value) => {
    setPage(1);
    setSize(value);
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    if (!hasPermission) return permissionError();
    setSelectAllChecked(checked);
    if (checked) {
      setSelectCheckData([...data]);
    } else {
      setSelectCheckData([]);
    }
  };

  const songCategoryTable = [
    {
      Header: "NO",
      body: "name",
      Cell: ({ index }) => <span>{(page - 1) * size + index + 1}</span>,
    },

    {
      Header: "IMAGE",
      body: "image",
      Cell: ({ row }) => <img src={row?.image} width="60px" height="60px" />,
    },
    {
      Header: "Category Name",
      body: "categoryName",
      Cell: ({ row }) => (
        <span className="text-capitalize fw-bold">{row?.name}</span>
      ),
    },

    {
      Header: "CREATE DATE",
      body: "createdAt",
      Cell: ({ row }) => (
        <span className="text-capitalize">
          {row?.createdAt ? dayjs(row?.createdAt).format("DD MMMM YYYY") : ""}
        </span>
      ),
    },
    {
      Header: "ACTION",
      body: "action",
      Cell: ({ row }) => (
        <div className="action-button">
          <Button
            btnIcon={<EditIcon />}
            onClick={() => {
              dispatch(openDialog({ type: "addSongCategory", data: row }));
            }}
          />
          <Button
            btnIcon={<TrashIcon />}
            onClick={() => handleDeleteCategory(row)}
          />
        </div>
      ),
    },
  ];

  const handleDeleteCategory = (row) => {
    if (!hasPermission) return permissionError();

    const data = warning("Delete");
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(deleteSongCategory(row?._id));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="user-table">
        <div className="user-table-top">
          <div className="row align-items-start">
            <div className="col-6">
              <h5
                style={{
                  fontWeight: "500",
                  fontSize: "20px",
                  marginTop: "5px",
                  marginBottom: "15px",
                }}
              >
                Song Category
              </h5>
            </div>
            <div
              className="col-6 new-fake-btn d-flex justify-content-end"
              style={{ marginTop: "5px" }}
            >
              <Button
                btnIcon={<AddIcon />}
                btnName={"New"}
                newClass={"rounded"}
                onClick={() => {
                  dispatch(openDialog({ type: "addSongCategory" }));
                }}
              />
            </div>
          </div>
        </div>
        <Table
          data={data}
          mapData={songCategoryTable}
          serverPerPage={size}
          serverPage={page}
          handleSelectAll={handleSelectAll}
          selectAllChecked={selectAllChecked}
          type={"server"}
        />
        <div className="mt-3">
          <Pagination
            type={"server"}
            activePage={page}
            rowsPerPage={size}
            userTotal={totalSongCategory}
            setPage={setPage}
            handleRowsPerPage={handleRowsPerPage}
            handlePageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default SongCategory;
