import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isSkeleton } from "../utils/allSelector";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function Table(props) {
  const {
    data,
    checkBoxShow,
    mapData,
    Page,
    PerPage,
    type,
    style,
    onChildValue,
    selectAllChecked,
    handleSelectAll,
  } = props;
  const roleSkeleton = useSelector(isSkeleton);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [checkBox, setCheckBox] = useState();

  const sortedData =
    data?.length > 0 &&
    [...data].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

  const startIndex = ([Page] - 1) * PerPage;
  const endIndex = startIndex + PerPage;

  const currentPageData = Array.isArray(data)
    ? data.slice(startIndex, endIndex)
    : [];
  return (
    <>
      <div className="primeMain table-custom">
        <table
          width="100%"
          border
          className="primeTable text-center"
          style={{ maxHeight: "600px" }}
        >
          {roleSkeleton ? (
            <>
              <thead>
                <tr>
                  {mapData.map((res, i) => {
                    return (
                      <th
                        className={` ${res.thClass}`}
                        key={i}
                        width={res.width}
                        style={{ minWidth: res.width ? res.width : "100px" }}
                      >
                        <div
                          className={`${res.thClass}`}
                          style={{ height: "20px" }}
                        ></div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {Array(6)
                  .fill()
                  .map((res, i) => {
                    return (
                      <>
                        <tr key={i} style={{ height: "80px" }}>
                          {mapData?.map((res, ind) => {
                            return (
                              <td key={ind}>
                                <SkeletonTheme
                                  baseColor="#e2e5e7"
                                  highlightColor="#fff"
                                >
                                  <p className="d-flex justify-content-center ">
                                    <Skeleton
                                      height={20}
                                      width={130}
                                      style={{ borderRadius: "5px" }}
                                    />
                                  </p>
                                </SkeletonTheme>
                              </td>
                            );
                          })}
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </>
          ) : (
            <>
              <thead
                className=""
                style={{ zIndex: "1", position: "sticky", top: "0" }}
              >
                <tr>
                  {mapData?.map((res) => {
                    return (
                      <th className="fw-bold text-nowrap">
                        <div className="table-head">
                          {res?.Header === "checkBox" ? (
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={handleSelectAll}
                            />
                          ) : (
                            `${" "}${res?.Header}`
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {type == "server" && (
                <>
                  <tbody>
                    {sortedData.length > 0 ? (
                      <>
                        {(PerPage > 0
                          ? [sortedData].slice(
                              Page * PerPage,
                              Page * PerPage + PerPage
                            )
                          : sortedData
                        ).map((i, k) => {
                          return (
                            <>
                              <tr>
                                {mapData.map((res) => {
                                  return (
                                    <td>
                                      {res.Cell ? (
                                        <res.Cell row={i} index={k} />
                                      ) : (
                                        <span className={res.class}>
                                          {i[res.body]}
                                        </span>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan="25"
                          className="text-center"
                          style={{ height: "358px", borderBottom: "none" }}
                        >
                          No Data Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}

              {type == "client" && (
                <>
                  <tbody>
                    {currentPageData?.length > 0 ? (
                      <>
                        {currentPageData.map((i, k) => {
                          return (
                            <>
                              <tr>
                                {mapData.map((res) => {
                                  return (
                                    <td>
                                      {res.Cell ? (
                                        <res.Cell row={i} index={k} />
                                      ) : (
                                        <span className={res.class}>
                                          {i[res.body]}
                                        </span>
                                      )}
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <tr>
                        <td
                          colSpan="16"
                          className="text-center"
                          style={{ height: "358px", borderBottom: "none" }}
                        >
                          No Data Found !
                        </td>
                      </tr>
                    )}
                  </tbody>
                </>
              )}
            </>
          )}
        </table>
      </div>
    </>
  );
}

export default Table;
