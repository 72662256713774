import Swal from "sweetalert2";

export const warning = (confirm) => {
  return Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning", // Specify the icon here
    showCancelButton: true,
    confirmButtonText: confirm,
    customClass: {
      confirmButton: "btn bg-dark text-light m15-right",
      cancelButton: "btn bg-danger text-light",
    },
    buttonsStyling: false,
  });
};

export const permissionError = () => {
  return Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "You Do'nt Have Permission!",
  });
};
