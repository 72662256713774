import { apiInstance, apiInstanceFetch } from "../api/axiosApi";

// Set Token In Axios
export function setToken(token) {
  if (token) {
    return apiInstance
      ? (apiInstance.defaults.headers.common["Authorization"] = token)
      : (apiInstanceFetch.defaults.headers.common["Authorization"] =
          token);
  } else {
    return apiInstance
      ? delete apiInstance.defaults.headers.common["Authorization"]
      : delete apiInstanceFetch.defaults.headers.common["Authorization"];
  }
}

// Set Key In apiInstance
export function SetDevKey(key) {
  if (key) {
    return (apiInstance.defaults.headers.common["key"] = key);
  } else {
    return delete apiInstance.defaults.headers.common["key"];
  }
}
