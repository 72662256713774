import React, { useEffect, useState } from "react";
import Button from "../../extra/Button";
import Input from "../../extra/Input";
import Selector from "../../extra/Selector";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getCountry,
  addFakeUser,
  updateFakeUser,
} from "../../../redux/slice/userSlice";
import { closeDialog } from "../../../redux/slice/dialogueSlice";

function NewFakeUser(props) {
  const AgeNumber = Array.from(
    { length: 100 - 18 + 1 },
    (_, index) => index + 18
  );
  const { dialogue, dialogueType, dialogueData } = useSelector(
    (state) => state.dialogue
  );
  const { userProfile, historyData } = useSelector((state) => state.user);
  console.log("countryData", historyData);
  const dispatch = useDispatch();
  const [mongoId, setMongoId] = useState("");
  const [gender, setGender] = useState(null);
  const [fullName, setFullName] = useState("");
  const [nickName, setNickName] = useState("");
  const [mobileNumber, setMobileNumber] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [email, setEmail] = useState("");
  const [data, setData] = useState();
  const [country, setCountry] = useState("");
  const [countryDataSelect, setCountryDataSelect] = useState();
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState(
    dialogueData ? dialogueData?.image : ""
  );
  const [age, setAge] = useState(null);
  const [error, setError] = useState({
    fullName: "",
    nickName,
    mobileNumber: "",
    email: "",
    ipAddress: "",
    gender: "",
    country: "",
    age: "",
    image: "",
  });

  useEffect(() => {
    const countryDataName = historyData?.map((item) => item?.name?.common);
    setCountry(countryDataName);
  }, [historyData]);

  useEffect(() => {
    if (dialogueData) {
      setMongoId(dialogueData?._id);
      setFullName(dialogueData?.name);
      setNickName(dialogueData?.userName);
      setGender(dialogueData?.gender);
      setAge(dialogueData?.age);
      setCountry(dialogueData?.country);
      setEmail(dialogueData?.email);
      setIpAddress(dialogueData?.ipAddress);
      setMobileNumber(dialogueData?.mobileNumber);
      setImagePath(dialogueData?.image);
      setCountryDataSelect(dialogueData?.country);
    }
  }, [dialogueData]);

  useEffect(() => {
    dispatch(getCountry());
  }, [dispatch]);

  const handleSubmit = () => {
    if (
      !fullName ||
      !nickName ||
      !mobileNumber ||
      !email ||
      !age ||
      !gender ||
      !countryDataSelect ||
      !image ||
      !ipAddress
    ) {
      let error = {};
      if (!fullName) error.fullName = "Full Name Is Required !";
      if (!nickName) error.nickName = "Nick Name Is Required !";
      if (!mobileNumber) error.mobileNumber = "Mobile Number Is Required !";
      if (!email) {
        error.email = "Email Is Required !";
      }
      if (!gender) error.gender = "Gender Is Required !";
      if (!image) error.image = "Image Is Required !";
      if (!age) error.age = "Age is required !";
      if (!ipAddress);
      if (!countryDataSelect) error.country = "Country is required !";
      if (!age) error.age = "Age is required !";

      if (!ipAddress) {
        error.ipAddress = "Ip Address is required !";
      }
      return setError({ ...error });
    } else {
      let formaData = new FormData();
      formaData.append("name", fullName);
      formaData.append("userName", nickName);
      formaData.append("gender", gender);
      formaData.append("age", parseInt(age));
      formaData.append("image", image);
      formaData.append("ipAddress", ipAddress);
      formaData.append("country", countryDataSelect);
      formaData.append("email", email);
      formaData.append("mobileNumber", mobileNumber);

      if (mongoId) {
        let payload = { id: mongoId, data: formaData };
        dispatch(updateFakeUser(payload));
      } else {
        dispatch(addFakeUser(formaData));
      }
      dispatch(closeDialog());
    }
  };

  const handleImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
    setError("");
  };

  return (
    <div>
      <div className="general-setting fake-user ">
        <div className=" userSettingBox">
          <form>
            <div className="row d-flex  align-items-center">
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mb-1 mb-sm-0">
                <h5 className="mb-0">{dialogueData ? "Update Fake User" : "Create Fake User"}</h5>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end">
                <Button
                  btnName={"Back"}
                  newClass={"back-btn"}
                  onClick={() => dispatch(closeDialog())}
                />
              </div>
              <div
                className="col-12 d-flex justify-content-end align-items-center"
                style={{
                  paddingTop: "8px",
                  marginTop: "11px",
                  borderTop: "1px solid #c9c9c9",
                }}
              >
                <Button
                  newClass={"submit-btn-dialogue"}
                  btnName={"Submit"}
                  type={"button"}
                  onClick={handleSubmit}
                />
              </div>
              <div className="row mt-3">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Input
                    label={"Full Name"}
                    name={"fullName"}
                    placeholder={"Enter Details..."}
                    errorMessage={error.fullName && error.fullName}
                    defaultValue={dialogueData && dialogueData.name}
                    onChange={(e) => {
                      setFullName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          fullName: `Full Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          fullName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Input
                    label={"User name"}
                    name={"nickName"}
                    placeholder={"Enter Details..."}
                    errorMessage={error.nickName && error.nickName}
                    defaultValue={dialogueData && dialogueData.userName}
                    onChange={(e) => {
                      setNickName(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          nickName: `Nick Name Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          nickName: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Input
                    label={"E-mail Address"}
                    name={"email"}
                    errorMessage={error.email && error.email}
                    defaultValue={dialogueData && dialogueData.email}
                    placeholder={"Enter Details..."}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          email: `Email Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          email: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Input
                    label={"Mobile Number"}
                    name={"mobileNumber"}
                    type={"number"}
                    placeholder={"Enter Details..."}
                    errorMessage={error.mobileNumber && error.mobileNumber}
                    defaultValue={dialogueData && dialogueData.mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          mobileNumber: `Mobile Number Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          mobileNumber: "",
                        });
                      }
                    }}
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Input
                    label={"Ip Address"}
                    name={"ipAddress"}
                    type={"text"}
                    value={ipAddress}
                    placeholder={"Enter Details..."}
                    errorMessage={error.ipAddress && error.ipAddress}
                    defaultValue={dialogueData && dialogueData.ipAddress}
                    disabled={dialogueData ? true : false}
                    onChange={(e) => {
                      setIpAddress(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          ipAddress: `Ip Address Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          ipAddress: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Selector
                    label={"Gender"}
                    selectValue={gender}
                    placeholder={"Select Gender"}
                    selectData={["Male", "Female"]}
                    errorMessage={error.gender && error.gender}
                    defaultValue={dialogueData && dialogueData.gender}
                    onChange={(e) => {
                      setGender(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          gender: `Gender Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          gender: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2">
                  <Selector
                    label={"Age"}
                    selectValue={age}
                    placeholder={"Select Age"}
                    errorMessage={error.age && error.age}
                    defaultValue={dialogueData && dialogueData.age}
                    selectData={AgeNumber}
                    onChange={(e) => {
                      setAge(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          age: `Age Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          age: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2 country-dropdown">
                  <Selector
                    label={"Country"}
                    selectValue={countryDataSelect}
                    defaultValue={dialogueData && dialogueData.country}
                    placeholder={"Select Country"}
                    selectData={country}
                    errorMessage={error.country && error.country}
                    onChange={(e) => {
                      setCountryDataSelect(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          country: `Country Is Required`,
                        });
                      } else {
                        return setError({
                          ...error,
                          country: "",
                        });
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2 ">
                  <Input
                    type={"file"}
                    label={"Image"}
                    accept={"image/png, image/jpeg"}
                    errorMessage={error.image && error.image}
                    onChange={handleImage}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6"></div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-2 fake-create-img mb-2">
                  <img
                    src={imagePath && imagePath}
                    alt=""
                    draggable="false"
                    className={`${
                      (!imagePath || imagePath === "") && "d-none"
                    } `}
                    data-class={`showImage`}
                    style={{ width: "100px", height: "100px" }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default NewFakeUser;
